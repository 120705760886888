import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);


export const options = {
    responsive: true,
    plugins: {
        legend: {
            display: true,
            position: "bottom",
            align: "center",
            labels: {
                font: {
                    size: 14,
                    color: "#000",
                },
                usePointStyle: true,
                padding: 30,
            },
        },
        title: {
            display: false,
        },
        tooltip: {
            callbacks: {
                label: function (tooltipItems) {
                    let label = tooltipItems.label || '';
                    if (label) {
                        label += ': ';
                    }
                    if (tooltipItems.parsed !== null) {
                        label += tooltipItems.formattedValue + '%';
                    }
                    return label;
                }
            }
        },
    },
};

export function PieChart({ chartData }) {

    const data = {
        labels: chartData?.map((item) => item.year),
        datasets: [
            {
                label: "% of Users",
                data: chartData?.map((item) => item.percentage),
                backgroundColor: ["#FCD228", "#004f87", "#A3F7B5", "#65AFFF", "#40C9A2"],
                borderWidth: 0,
            },
        ],
    };
    return (
        <div className="db-card  pie-chart">
            <h2>Percentage of Users Registered in last 5 years</h2>
            <div className="mt-5">
                <Pie data={data} options={options} />
            </div>
        </div>
    );
}

import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {
    Box,
    Button,
    FormControlLabel,
    RadioGroup,
    Radio
} from "@mui/material";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import {Is_Ticket_Added } from "../services/Url";
import { toast } from "react-toastify";

const BaseUrl = process.env.REACT_APP_BASE_URL;

export default function TicketModal(props) {
    const [modalOpen, setModalOpen] = React.useState(false);

    const handleClickOpen = () => {
        setModalOpen(true);
    };

    const [selectedOption, setSelectedOption] = React.useState('');

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleSubmit = (e) => {
        toast.dismiss();
        e.preventDefault();
        const url = BaseUrl + Is_Ticket_Added;
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('token'),
            },
            body: JSON.stringify({
                totalPrice: props.totalAmount,
                discountPercent: props.discount ? parseInt(props.discount) : 0,
                tripid: props.id
            })
        }).then((res) => {
            if (res.ok) {
                setModalOpen(false);
                props.isTicAllHandler(true);
            }
            else {
                throw new Error("Something went wrong, Please try again!");
            }
        }).catch((err) => { toast.error(err.message) })
    }

    return (
        <div>
            <Alert severity="info">
                <AlertTitle>Have you uploaded all tickets?</AlertTitle>
                <RadioGroup
                    name="ticket"
                    id="ticket"
                    value={selectedOption}
                    onChange={handleOptionChange}
                >
                    <FormControlLabel
                        value="yes"
                        control={<Radio
                            onClick={handleClickOpen}
                        />}
                        label="Yes"
                    />
                </RadioGroup>
            </Alert>
            <Dialog
                className="custom_modal"
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you don't want to add any more tickets?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Box className="btn_wpr">
                        <Button onClick={() => { setModalOpen(false); setSelectedOption('') }}>
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit}>
                            Confirm
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </div>
    );
}

import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import { Loader } from "../../Components/Loader";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { getData } from "../../services/Service";
import PdfViewer from "../../Components/PdfViewer";
import {
  Approve_Bank_Receipt,
  Get_Bank_Receipt,
  Reject_Bank_Receipt,
} from "../../services/Url";
import { toast } from "react-toastify";

const ImageBase = process.env.REACT_APP_IMAGE_BASE;

const Receipt = () => {
  const [isloading, setIsloading] = useState(false);
  const [receiptData, setReceiptData] = useState();
  const [data, setData] = useState();
  const { receiptId, tripId } = useParams();
  const navigate = useNavigate();

  function isPDF(fileName) {
    return fileName.toLowerCase().endsWith(".pdf") || fileName.toLowerCase().endsWith(".doc");
  }

  useEffect(() => {
    getData(Get_Bank_Receipt, receiptId, setReceiptData, setIsloading);
  }, []);


  const approverHandler = () => {
    getData(Approve_Bank_Receipt, tripId, setData, setIsloading).then(() => {
      setIsloading(true);
      toast.success("Successfully Approved the Receipt");
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    });
  };
  const rejectHandler = () => {
    getData(Reject_Bank_Receipt, tripId, setData, setIsloading).then(() => {
      setIsloading(true);
      toast.success("Successfully Recjected the Receipt");
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    });
  };
  return (
    <Box
      display="flex"
      className="box-wrapper br-24 "
      flexDirection="column"
      justifyContent="start"
    >
      {isloading ? <Loader /> : ""}
      <div className="heading-btn_wrapper">
        <h5
          style={{ display: "inline-flex", cursor: "pointer" }}
          className="fz-24 fw-500"
        >
          View Bank Receipt
        </h5>
      </div>
      <div className="receipt-box">
        <h4>Bank Receipt</h4>
        <div className="receipt-img">
          {receiptData?.bank_receipt &&
            (isPDF(receiptData?.bank_receipt) ? (
              <PdfViewer
                pdfSrc={ImageBase + "receipt/" + receiptData?.bank_receipt}
              />
            ) : (
              <img
                src={
                  ImageBase
                    ? ImageBase + "receipt/" + receiptData?.bank_receipt
                    : ""
                }
                alt="img"
              />
            ))}
        </div>
        <div className="d-flex justify-content-between receipt-btns">
          <Button onClick={approverHandler}>
            <CheckIcon
              sx={{ fontSize: 20, color: "#fff", marginRight: "5px" }}
            />
            Accept
          </Button>
          <Button onClick={rejectHandler}>
            <CloseIcon
              sx={{ fontSize: 20, color: "#fff", marginRight: "5px" }}
            />
            Reject
          </Button>
        </div>
      </div>
    </Box>
  );
};

export default Receipt;

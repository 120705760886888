import React, { useState, useContext } from "react";
import AdminContext from "../context/admin-context";
import { Link } from "react-router-dom";
import { Button, Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import binIcon from "../Components/assets/images/bin-icon.svg";
import binIconActive from "../Components/assets/images/bin-white.svg";
import { deleteData } from "../services/Service";

export default function DeleteModal({ id, url, type, loadingHandler }) {
  const [open, setOpen] = useState(false);
  const ctx = useContext(AdminContext);
  console.log("Remove_Ticket", url)
  const handleClickOpen = () => {
    setOpen(true);
  };
  const deleteHandler = (id, url, type) => {
    deleteData(url, id, loadingHandler, type).then((data) => {
      if (data === 200) {
        if (type === "flight ticket") {
          ctx.flightTicHandler();
        } else if (type === "hotel ticket") {
          ctx.hotelTicHandler();
        } else if (type === "activity ticket") {
          ctx.activityTicHandler();
        } else {
          ctx.deleteToggler();
        }
      }
    });
  };

  return (
    <>
      <Link onClick={handleClickOpen}>
        <img src={binIcon} alt="bin-icon" />
        <img src={binIconActive} alt="bin-icon" />
      </Link>
      <Dialog
        className="custom_modal"
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="modal-img">
              <img src={binIcon} alt="img" />
            </div>
            Are you sure you want to delete the {type}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box className="btn_wpr">
            <Button
              onClick={() => {
                setOpen(false);
                deleteHandler(id, url, type);
              }}
            >
              Delete
            </Button>
            <Button onClick={() => setOpen(false)} autoFocus>
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

import React from "react";
import { Link } from "react-router-dom";
import CustomSwitch from "../../Components/CustomSwitch";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { Disable_User, Enable_User } from "../../services/Url";
import dummyProfile from "../../Components/assets/images/profilePicture.png";
import { toast } from "react-toastify";
import { ENABLE } from "../../services/Constants";
import { userAccess } from "../../services/Service";

const ImageBase = process.env.REACT_APP_IMAGE_BASE;

export default function UserList({ list }) {

  const userAccessHandler = (value, id) => {
    //enabling / disabling user access
    toast.dismiss();
    if (value === ENABLE) {
      userAccess(Enable_User, id, "unblocked");
    } else {
      userAccess(Disable_User, id, "blocked");
    }
  };
  const tableData = () => {
    return (
      <div className="table-responsive">
        <TableContainer className="tbl_mn">
          <Box className="pe-3 mt-3">
            <Table className="table-design table_center user_table">
              <TableHead>
                <TableRow className="tbl_rw">
                  <TableCell className="fw-bold text-center">
                    User Image
                  </TableCell>
                  <TableCell className="fw-bold">Name</TableCell>
                  <TableCell className="fw-bold nowrap">Email </TableCell>
                  <TableCell className="fw-bold nowrap">Phone Number </TableCell>
                  <TableCell className="fw-bold text-center">
                    Block/Unblock
                  </TableCell>
                  <TableCell className="fw-bold text-center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list?.length > 0 ? (
                  list?.map((item) => {
                    return (
                      <TableRow key={item._id}>
                        <TableCell className="fz-16 text-center" scope="row">
                          <div className="profile-img">
                            <img
                              src={
                                item.profile_picture
                                  ? ImageBase + "profile/" + item.profile_picture
                                  : dummyProfile
                              }
                              alt="img"
                            />
                          </div>
                        </TableCell>
                        <TableCell className="fz-16 nowrap">{item.name}</TableCell>
                        <TableCell className="fz-16 nowrap">{item.email}</TableCell>
                        <TableCell className="fz-16">
                          {item.phone_number}
                        </TableCell>
                        <TableCell
                          className="fz-16 text-center"
                          onClick={(event) => event.stopPropagation()}
                        >
                          <CustomSwitch
                            id={item._id}
                            status={item.status}
                            accessHandler={userAccessHandler}
                          />
                        </TableCell>
                        <TableCell
                          className="fz-16 text-center"
                          onClick={(event) => event.stopPropagation()}
                        >
                          <Link to={`/userdetail/${item._id}`}>
                            <VisibilityIcon sx={{ color: "#004F87" }} />
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={12} className="empty-text text-center">
                      No data found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        </TableContainer>
      </div>
    );
  };

  return (
    <div className="db-card">
      <div className="d-flex justify-content-between">
        <h5>User Management List</h5>
        <Link className="show-link" to="/usermanagement">
          Show All
        </Link>
      </div>
      {tableData()}
    </div>
  );
}

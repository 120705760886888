import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Moment from "react-moment";
import Stack from "@mui/material/Stack";
import { Trip_Management_All, Trip_Management_New } from "../../services/Url";
import { Loader } from "../../Components/Loader";
import { post, searchData } from "../../services/Service";
import Search from "../../Components/Search";
import { ALL_TRIP, NEW_TRIP } from "../../services/Constants";

export default function TripManagement() {
  const [data, setData] = useState();
  const [isloading, setIsloading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [error, setError] = useState(false);
  const [page, setPage] = useState(1);
  const [activeTab, setActiveTab] = useState(ALL_TRIP);
  let url;

  if (activeTab === ALL_TRIP) {
    url = Trip_Management_All;
  } else {
    url = Trip_Management_New;
  }

  const navigate = useNavigate();
  let pageData = JSON.stringify({
    search_string: "",
    page: page,
  });

  useEffect(() => {
    setIsloading(true);
    setTimeout(() => {
      post(url, pageData, setData, setIsloading);
    }, 600);
  }, [url]);


  useEffect(() => {
    //search API
    if (!/^[a-zA-Z0-9]/.test(searchText[0])) {
      //first character shouldn't be special character
      setError(true);
      return;
    }
    setError(false);
    const delay = 400;
    let timerId;
    let pageData = JSON.stringify({
      search_string: searchText,
      page: page,
    });
    //search API
    const delayedSearch = () => {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        // Make API call here
        searchData(url, pageData, setData, setIsloading);
      }, delay);
    };
    delayedSearch();
    return () => {
      clearTimeout(timerId);
    };
  }, [searchText]);

  const pagingHandler = (event, value) => {
    //pagination api
    setPage(value);
    let pageData = {
      search_string: searchText,
      page: value,
    };
    pageData = JSON.stringify(pageData);
    post(url, pageData, setData, setIsloading);
  };

  const tableData = () => {
    return (
      <TableContainer className="tbl_mn br-24 br-btm-0 ">
        <Box className="table-responsive table-line">
          <Table className="table-design table_center">
            <TableHead>
              <TableRow className="tbl_rw">
                <TableCell className="fw-bold nowrap">Name</TableCell>
                <TableCell className="fw-bold nowrap">Booking ID </TableCell>
                <TableCell className="fw-bold nowrap">Start Date</TableCell>
                <TableCell className="fw-bold nowrap">End Date</TableCell>
                <TableCell className="fw-bold th-width">Source</TableCell>
                <TableCell className="fw-bold th-width">Destination</TableCell>
                <TableCell className="fw-bold nowrap">Total Amount </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.docs.length > 0 && !error ? (
                data?.docs.map((item) => {
                  return (
                    <TableRow
                      key={item._id}
                      onClick={() =>
                        navigate(`/viewtrip/${item.userId}/${item._id}`)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <TableCell className="fz-16 nowrap">
                        {item.result.name}
                      </TableCell>
                      <TableCell className="fz-16 nowrap">{item._id}</TableCell>
                      <TableCell className="fz-16 nowrap">
                        <Moment format="DD/MM/YYYY">
                          {item.trip_start_date}
                        </Moment>
                      </TableCell>
                      <TableCell className="fz-16 nowrap">
                        <Moment format="DD/MM/YYYY">
                          {item.trip_end_date}
                        </Moment>
                      </TableCell>
                      <TableCell className="fz-16 td-width">
                        {item.source}
                      </TableCell>
                      <TableCell className="fz-16 td-width">
                        {item.destination}
                      </TableCell>
                      <TableCell className="fz-16 nowrap">
                        ${item.totalTicketAmount}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={12} className="empty-text text-center">
                    No data found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "30px",
          }}
        >
          {data?.docs?.length > 0 ? (
            <Stack spacing={2}>
              <Pagination count={data?.totalPages} onChange={pagingHandler} page={page} />
            </Stack>
          ) : (
            ""
          )}
        </Box>
      </TableContainer>
    );
  };

  return (
    <Box
      display="flex"
      className="box-wrapper br-24 table_bx"
      flexDirection="column"
      justifyContent="start"
    >
      {isloading ? <Loader /> : ""}
      <div className="heading-btn_wrapper">
        <h5 style={{ display: "inline-flex" }} className="fz-24 fw-500 mb-0">
          Trip Management
        </h5>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div className="tabs-btn">
          <button
            className={activeTab === ALL_TRIP ? "active" : ""}
            onClick={() => {
              setActiveTab(ALL_TRIP);
              setPage(1);
            }
            }
          >
            All trip request
          </button>
          <button
            className={activeTab === NEW_TRIP ? "active" : ""}
            onClick={() => {
              setActiveTab(NEW_TRIP);
              setPage(1);
            }}
          >
            New Trip request
          </button>
        </div>
        <Search searchHandler={setSearchText} />
      </div>
      {tableData()}
    </Box>
  );
}

import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Change_Password } from "../../services/Url";
import { toast } from "react-toastify";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import * as Yup from "yup";
import { useFormik } from "formik";

const BaseUrl = process.env.REACT_APP_BASE_URL;

const ChangePassword = () => {
  const [showOldPass, setShowOldPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConPass, setShowConPass] = useState(false);

  const handleClickShowOldPass = () => setShowOldPass(!showOldPass);
  const handleMouseDownOldPass = () => setShowOldPass(!showOldPass);

  const handleClickShowNewPass = () => setShowNewPass(!showNewPass);
  const handleMouseDownNewPass = () => setShowNewPass(!showNewPass);

  const handleClickShowConPass = () => setShowConPass(!showConPass);
  const handleMouseDownConPass = () => setShowConPass(!showConPass);

  const validationSchema = Yup.object().shape({
    oldPass: Yup.string().required("Old password is required"),
    newPass: Yup.string()
      .required("New password is required")
      .notOneOf(
        [Yup.ref("oldPass")],
        "New password must not match the old password"
      ),
    confirmPass: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("newPass")], "Passwords must match"),
  });

  const formik = useFormik({
    initialValues: {
      oldPass: "",
      newPass: "",
      confirmPass: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      toast.dismiss();
      fetch(BaseUrl + Change_Password, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          old_password: values.oldPass,
          password: values.newPass,
        }),
      })
        .then((res) => {
          if (!res.ok && res.status === 400) {
            throw new Error("Incorrect Old Password!");
          }
          if (!res.ok) {
            throw new Error("An error occurred while changing the password");
          }
          return res.json();
        })
        .then((data) => {
          formik.resetForm();
          toast.success(data.message);
        })
        .catch((err) => {
          toast.error(err.message);
        });
    },
  });

  return (
    <Box className="changePass_cont">
      <Box className="box-wrapper">
        <div className="heading-btn_wrapper mb-3">
          <h5 className="fz-24 fw-500">Change Password</h5>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-6">
              <Box className="form_wrapper pwd_form px-4">
                <Box className="inpt_bx mb-4">
                  <Typography variant="h6">Current Password</Typography>
                  <TextField
                    placeholder="**************"
                    variant="outlined"
                    fullWidth
                    name="oldPass"
                    type={showOldPass ? "text" : "password"}
                    {...formik.getFieldProps("oldPass")}
                    error={
                      formik.touched.oldPass && Boolean(formik.errors.oldPass)
                    }
                    helperText={formik.touched.oldPass && formik.errors.oldPass}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowOldPass}
                            onMouseDown={handleMouseDownOldPass}
                          >
                            {showOldPass ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box className="inpt_bx mb-4">
                  <Typography variant="h6">New Password</Typography>
                  <TextField
                    placeholder="**************"
                    variant="outlined"
                    fullWidth
                    name="newPass"
                    type={showNewPass ? "text" : "password"}
                    {...formik.getFieldProps("newPass")}
                    error={formik.errors.newPass && formik.touched.newPass}
                    helperText={formik.touched.newPass && formik.errors.newPass}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowNewPass}
                            onMouseDown={handleMouseDownNewPass}
                          >
                            {showNewPass ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box className="inpt_bx">
                  <Typography variant="h6">Confirm Password</Typography>
                  <TextField
                    placeholder="**************"
                    variant="outlined"
                    fullWidth
                    name="confirmpass"
                    type={showConPass ? "text" : "password"}
                    {...formik.getFieldProps("confirmPass")}
                    error={
                      formik.errors.confirmPass && formik.touched.confirmPass
                    }
                    helperText={
                      formik.touched.confirmPass && formik.errors.confirmPass
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConPass}
                            onMouseDown={handleMouseDownConPass}
                          >
                            {showConPass ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box>
                  <Button
                    className="btn btn-design"
                    sx={{ mt: 4, mb: 3 }}
                    type="submit"
                  >
                    Update Password
                  </Button>
                </Box>
              </Box>
            </div>
          </div>
        </form>
      </Box>
    </Box>
  );
};

export default ChangePassword;

import React from "react";
import { Link } from "react-router-dom";
import dummyImg from "../../Components/assets/images/placeholder-img.png";

import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

const ImageBase = process.env.REACT_APP_IMAGE_BASE;


export default function HolidayList({ list }) {
  const tableData = () => {
    return (
      <TableContainer className="tbl_mn table-responsive">
        <Box className="pe-3 mt-3">
          <Table className="table-design table_center holiday_table">
            <TableHead>
              <TableRow className="tbl_rw">
                <TableCell className="fw-bold text-center">
                  Cover Image
                </TableCell>
                <TableCell className="fw-bold">Title</TableCell>
                <TableCell className="fw-bold"> Description </TableCell>
                <TableCell className="fw-bold"> Price </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list?.length > 0 ? (
                list?.map((item, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell className="fz-16 text-center" scope="row">
                        <div className="cover-img">
                          <img
                            src={
                              item.cover_image
                                ? ImageBase + "coverimage/" + item.cover_image
                                : dummyImg
                            }
                            alt="img"
                          />
                        </div>
                      </TableCell>
                      <TableCell className="fz-16"><p>{item.title}</p></TableCell>
                      <TableCell className="fz-16">
                        <p className="multiline-ellipsis mb-0">{item.description}</p>
                      </TableCell>
                      <TableCell className="fz-16 nowrap"><p>${item.price}</p></TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={12} className="empty-text text-center">
                    No data found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>
    );
  };

  return (
    <div className="db-card">
      <div className="d-flex justify-content-between">
        <h5>Holiday Packages Management List</h5>
        <Link className="show-link" to="/holidaypackage">
          Show All
        </Link>
      </div>
      {tableData()}
    </div>
  );
}

import React from "react";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Moment from "react-moment";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

export default function TripList({ list }) {
  const tableData = () => {
    return (
      <div className="table-responsive">
        <TableContainer className="tbl_mn ">
          <Box className="pe-3 mt-3">
            <Table className="table-design table_center trip_table">
              <TableHead>
                <TableRow className="tbl_rw">
                  <TableCell className="fw-bold nowrap">Booking ID </TableCell>
                  <TableCell className="fw-bold nowrap">Start Date</TableCell>
                  <TableCell className="fw-bold nowrap">End Date</TableCell>
                  <TableCell className="fw-bold nowrap">
                    Total Amount{" "}
                  </TableCell>
                  <TableCell className="fw-bold text-center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list?.length > 0 ? (
                  list?.map((item) => {
                    return (
                      <TableRow key={item._id}>
                        <TableCell className="fz-16 nowrap">
                          {item._id}
                        </TableCell>
                        <TableCell className="fz-16 nowrap">
                          <Moment format="DD/MM/YYYY">
                            {item.trip_start_date}
                          </Moment>
                        </TableCell>
                        <TableCell className="fz-16 nowrap">
                          <Moment format="DD/MM/YYYY">
                            {item.trip_end_date}
                          </Moment>
                        </TableCell>
                        <TableCell className="fz-16 nowrap">
                          ${item.total_amount}
                        </TableCell>
                        <TableCell
                          className="fz-16 text-center"
                          onClick={(event) => event.stopPropagation()}
                        >
                          <Link to={`/viewtrip/${item.userId}/${item._id}`}>
                            <VisibilityIcon sx={{ color: "#004F87" }} />
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={12} className="empty-text text-center">
                      No data found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        </TableContainer>
      </div>
    );
  };

  return (
    <div className="db-card">
      <div className="d-flex justify-content-between">
        <h5>Trip Management List</h5>
        <Link className="show-link" to="/tripmanagement">
          Show All
        </Link>
      </div>
      {tableData()}
    </div>
  );
}

import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from "@mui/material";
import dummyProfile from "../../Components/assets/images/profilePicture.png";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import {
    Delete_Itinerary,
    Get_Itinerary_List
} from "../../services/Url";
import { Loader } from "../../Components/Loader";
import { post, searchData } from "../../services/Service";
import Search from "../../Components/Search";
import DeleteModal from "../../Components/DeleteModal";
import AdminContext from "../../context/admin-context";
import Moment from "react-moment";

const ImageBase = process.env.REACT_APP_IMAGE_BASE;

const star = (num) => {
    const arr = new Array(num).fill('⭐');
    return arr;
}

export default function Itinerary() {
    const [isloading, setIsloading] = useState(true);
    const [data, setData] = useState();
    const [searchText, setSearchText] = useState("");
    const [error, setError] = useState(false);
    const [page, setPage] = useState(1);
    const ctx = useContext(AdminContext);
    const navigate = useNavigate();

    let pageData = JSON.stringify({
        search_string: "",
        page: page,
    });

    useEffect(() => {
        setTimeout(() => {
            post(Get_Itinerary_List, pageData, setData, setIsloading);
        }, 1500);
    }, []);
    
    useEffect(() => {
        if (!/^[a-zA-Z0-9]/.test(searchText[0])) {
            //first character shouldn't be special character
            setError(true);
            return;
        }
        setError(false);
        const delay = 500;
        let timerId;
        let pageData = JSON.stringify({
            search_string: searchText,
            page: page,
        });
        const delayedSearch = () => {
            clearTimeout(timerId);
            timerId = setTimeout(() => {
                // Make API call here
                searchData(Get_Itinerary_List, pageData, setData);
            }, delay);
        };

        delayedSearch();
        return () => {
            clearTimeout(timerId);
        };
    }, [searchText]);

    const pagingHandler = (event, value) => {
        setPage(value);
        let pageData = {
            search_string: searchText,
            page: value,
        };
        pageData = JSON.stringify(pageData);
        post(Get_Itinerary_List, pageData, setData, setIsloading);
    };

    useEffect(() => {
        //to hit api whenever an item is deleted
        setTimeout(() => {
            post(Get_Itinerary_List, pageData, setData, setIsloading);
        }, 1500);
    }, [ctx.isDeleted, pageData]);

console.log("data==>",data)

    const tableData = () => {
        return (
            <TableContainer className="tbl_mn br-24 br-btm-0 ">
                <Box className="table-responsive table-line">
                    <Table className="table-design table_center user-table-lg">
                        <TableHead>
                            <TableRow className="tbl_rw">
                                <TableCell className="fw-bold text-center text-nowrap">
                                    User Image
                                </TableCell>
                                <TableCell className="fw-bold text-nowrap">Name</TableCell>
                                <TableCell className="fw-bold text-nowrap">Email </TableCell>
                                <TableCell className="fw-bold text-nowrap">Phone Number </TableCell>
                                <TableCell className="fw-bold text-nowrap">Destination</TableCell>
                                <TableCell className="fw-bold text-nowrap">Date</TableCell>
                                <TableCell className="fw-bold text-nowrap">Time</TableCell>
                                <TableCell className="fw-bold text-nowrap">Total No. Days</TableCell>
                                <TableCell className="fw-bold text-nowrap">Budget</TableCell>
                                <TableCell className="fw-bold text-nowrap">
                                    Accommodation Preference
                                </TableCell>
                                <TableCell className="fw-bold text-center"> Action </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.docs?.length > 0 && !error ? (
                                data?.docs?.map((item) => {

                                    const [hours, minutes] = item?.time?.split(':');

                                    return (
                                        <TableRow
                                            key={item._id}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => navigate(`/viewTripItinerary/${item._id}`)}
                                        >
                                            <TableCell
                                                className="fz-16 text-center"
                                                scope="row"
                                            >
                                                <div className="profile-img">
                                                    <img
                                                        src={
                                                            item.result.profile_picture
                                                                ? ImageBase +
                                                                "profile/" +
                                                                item.result.profile_picture
                                                                : dummyProfile
                                                        }
                                                        alt="img"
                                                    />
                                                </div>
                                            </TableCell>
                                            <TableCell className="fz-16 text-nowrap">
                                                {item.result?.name}
                                            </TableCell>
                                            <TableCell className="fz-16 text-nowrap">
                                                {item.result?.email}
                                            </TableCell>
                                            <TableCell className="fz-16 text-nowrap">
                                                {item.result?.country_code} {item.result.phone_number}
                                            </TableCell>
                                             <TableCell className="fz-16 text-nowrap">
                                                {item.destination}
                                            </TableCell>
                                            <TableCell className="fz-16 text-nowrap">
                                            <Moment format="DD/MM/YYYY">
                                            {item.date}
                                          </Moment>
                                            </TableCell>
                                            <TableCell className="fz-16 text-nowrap">
                                                 {`${hours}:${minutes}`}
                                            </TableCell>
                                           
                                            <TableCell className="fz-16">
                                                {item.days}
                                            </TableCell>
                                            <TableCell className="fz-16 text-nowrap">
                                                ${item.budget}
                                            </TableCell>   
                                            <TableCell className="fz-16 text-nowrap">
                                                {star(item.accommodation)}
                                            </TableCell>
                                            <TableCell
                                                className="fz-16 table-actions text-center"
                                                onClick={(event) => event.stopPropagation()}
                                            >
                                                <DeleteModal
                                                    id={item._id}
                                                    url={Delete_Itinerary}
                                                    type="Trip Itinerary"
                                                    loadingHandler={setIsloading}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={12} className="empty-text text-center">
                                        No data found
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Box>
                <Box
                    style={{
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "30px",
                    }}
                >
                    {data?.docs?.length > 0 ? (
                        <Stack spacing={2}>
                            <Pagination count={data?.totalPages} onChange={pagingHandler} />
                        </Stack>
                    ) : (
                        ""
                    )}
                </Box>
            </TableContainer>
        );
    };

    return (
        <Box
            display="flex"
            className="box-wrapper br-24 table_bx"
            flexDirection="column"
            justifyContent="start"
        >
            {isloading ? <Loader /> : ""}
            <div className="heading-btn_wrapper">
                <h5 style={{ display: "inline-flex" }} className="fz-24 fw-500 mb-0">
                    Trip Itinerary Management
                </h5>
                <div className="w-40">
                    <Search searchHandler={setSearchText} />
                </div>
            </div>
            {tableData()}
        </Box>
    );
}

import React, { useEffect } from "react";
import { NavLink, Link, useNavigate, useLocation } from "react-router-dom";
import logo from "../Components/assets/images/logo.png";
import dashIcon from "../Components/assets/images/dashboard.png";
import dashActive from "../Components/assets/images/dashboard-active.png";
import manageIcon from "../Components/assets/images/management.png";
import manageActive from "../Components/assets/images/management-active.png";
import LogoutModal from "../Components/LogoutModal";
import tripIcon from "../Components/assets/images/trip.png";
import tripActive from "../Components/assets/images/trip-icon-active.png";
import offerIcon from "../Components/assets/images/offer.png";
import offerActive from "../Components/assets/images/offer-active.png";
import galleryIcon from "../Components/assets/images/galleryIcon.png";
import galleryActive from "../Components/assets/images/gallery-active.png";
import chatIcon from "../Components/assets/images/chatIcon.png";
import chatActive from "../Components/assets/images/chat-active.png";
import settingIcon from "../Components/assets/images/settingsIcon.png";
import settingActive from "../Components/assets/images/settings-active.png";
import holidayIcon from "../Components/assets/images/holidayIcon.png";
import holidayActive from "../Components/assets/images/trip-active.png";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import privacyIcon from "../Components/assets/images/insurance.png";
import privacyActive from "../Components/assets/images/insurance-active.png";
import termsIcon from "../Components/assets/images/terms-and-conditions.png";
import termsActive from "../Components/assets/images/terms-and-conditions-active.png";
import passwordIcon from "../Components/assets/images/reset-password.png";
import passwordActive from "../Components/assets/images/reset-password-active.png";
import contactIcon from "../Components/assets/images/customer-service.png";
import contactActive from "../Components/assets/images/customer-service-active.png";
import subsIcon from "../Components/assets/images/subscribe.png";
import subsIconActive from "../Components/assets/images/subscribe_active.png";
import itinerary from "../Components/assets/images/trip-planner.png";
import itineraryActive from "../Components/assets/images/trip-planner-active.png";
import LogoSm from "../Components/assets/images/yone_travel_logo.png";


const Sidebar = (props) => {
  const auth = localStorage.getItem("token");
  let navigate = useNavigate();
  const location = useLocation();
  const pathname = window.location.pathname;
  const isToggler = props.isToggleActive;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (!auth || auth === "" || auth === undefined) {
      navigate("/login");
    }
  }, [auth]);

  const settingLink =
    location.pathname === "/term&condition" ||
    location.pathname === "/changepassword" ||
    location.pathname === "/contactus" ||
    location.pathname === "/viewterms" ||
    location.pathname === "/viewpolicy" ||
    location.pathname === "/editcontact";


  const offerLink =
    location.pathname.includes("offeredit") ||
    location.pathname === "/offeradd";

  const holidayLink =
    location.pathname === "/holidayadd" ||
    location.pathname.includes("holidayedit");

  const userLink =
    location.pathname.includes("useredit") ||
    location.pathname.includes("userdetail");

  const tripLink =
    location.pathname.includes("viewtrip") ||
    location.pathname === "/receipt";

  const itineraryLink =
    location.pathname.includes("viewTripItinerary")

  const chatLink = location.pathname === "/chat";

  const subscriberLink = location.pathname === "/emailsubscriber";



  return (
    <div className={isToggler ? "sidebar toggle-active" : "sidebar"}>
      <div className="logo">
        <Link to="/">
          <img src={logo} alt="popular" loading="lazy" />
        </Link>
        <Link to="/">
          <img src={LogoSm} alt="popular" loading="lazy" />
        </Link>
      </div>
      <ul className="sidebarList">
        <li>
          <NavLink to="/">
            <span>
              <img src={dashIcon} alt="icon" />
              <img src={dashActive} alt="icon" />
            </span>
            {!isToggler && "Dashboard"}
          </NavLink>
        </li>
        <li>
          <NavLink to="/offermanagement" className={offerLink ? "active" : ""}>
            <span>
              <img src={offerIcon} alt="icon" />
              <img src={offerActive} alt="icon" />
            </span>
            {!isToggler && "Offer Management"}
          </NavLink>
        </li>
        <li>
          <NavLink to="/holidaypackage" className={holidayLink ? "active" : ""}>
            <span>
              <img src={holidayIcon} alt="icon" />
              <img src={holidayActive} alt="icon" />
            </span>
            {!isToggler && "Holiday Packages"}
          </NavLink>
        </li>
        <li>
          <NavLink to="/usermanagement" className={userLink ? "active" : ""}>
            <span>
              <img src={manageIcon} alt="icon" />
              <img src={manageActive} alt="icon" />
            </span>
            {!isToggler && "User Management"}
          </NavLink>
        </li>
        <li>
          <NavLink to="/tripmanagement" className={tripLink ? "active" : ""}>
            <span>
              <img src={tripIcon} alt="icon" />
              <img src={tripActive} alt="icon" />
            </span>
            {!isToggler && " Trip Management"}
          </NavLink>
        </li>
        <li>
          <NavLink to="/gallery">
            <span>
              <img src={galleryIcon} alt="icon" />
              <img src={galleryActive} alt="icon" />
            </span>
            {!isToggler && "Gallery"}
          </NavLink>
        </li>
        <li>
          <NavLink to="/chat" className={chatLink ? "active" : ""}>
            <span>
              <img src={chatIcon} alt="icon" />
              <img src={chatActive} alt="icon" />
            </span>
            {!isToggler && "Chat"}
          </NavLink>
        </li>
        <li>
          <NavLink to="/tripItinerary" className={itineraryLink ? "active" : ""}>
            <span>
              <img src={itinerary} alt="icon" />
              <img src={itineraryActive} alt="icon" />
            </span>
            {!isToggler && "Itinerary Management"}
          </NavLink>
        </li>
        <li className={settingLink ? "settings active" : "settings"}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <NavLink
                to="/privacypolicy"
                className={settingLink ? "active" : ""}
              >
                <span>
                  <img src={settingIcon} alt="icon" />
                  <img src={settingActive} alt="icon" />
                </span>
                {!isToggler && "Settings"}
              </NavLink>
            </AccordionSummary>
            <AccordionDetails>
              <ul className="setting-menu">
                <li>
                  <NavLink to="/privacypolicy" className={location.pathname.includes('/viewpolicy') && 'active'}>
                    {isToggler && (
                      <span>
                        <img src={privacyIcon} alt="icon" />
                        <img src={privacyActive} alt="icon" />
                      </span>
                    )}
                    {!isToggler && "Privacy & Policy "}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/term&condition" className={location.pathname.includes('/viewterms') && 'active'}>
                    {isToggler && (
                      <span>
                        <img src={termsIcon} alt="icon" />
                        <img src={termsActive} alt="icon" />
                      </span>
                    )}
                    {!isToggler && "Terms & Conditions"}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contactus" className={location.pathname.includes('/editcontact') && 'active'}>
                    {isToggler && (
                      <span>
                        <img src={contactIcon} alt="icon" />
                        <img src={contactActive} alt="icon" />
                      </span>
                    )}
                    {!isToggler && "Contact Us "}
                  </NavLink>
                </li>
                <li className="mb-3">
                  <NavLink to="/changepassword">
                    {isToggler && (
                      <span>
                        <img src={passwordIcon} alt="icon" />
                        <img src={passwordActive} alt="icon" />
                      </span>
                    )}
                    {!isToggler && " Change Password"}
                  </NavLink>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
        </li>
        {/* <li>
          <NavLink to="/emailsubscriber" className={subscriberLink ? "active" : ""}>
            <span>
              <img src={subsIcon} alt="icon" />
              <img src={subsIconActive} alt="icon" />
            </span>
            {!isToggler && "Subscribers List"}
          </NavLink>
        </li> */}
        <li>
          <a className="logout_btn">
            <LogoutModal toggler={isToggler} />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;

import React, { useState, useEffect, useContext } from "react";
import Moment from "react-moment";
import AeroIcon from "../../Components/assets/images/aeroplane.svg";
import departIcon from "../../Components/assets/images/areo-depart.svg";
import hotelIcon from "../../Components/assets/images/accomodation.svg";
import activityIcon from "../../Components/assets/images/right-arrow.png";
import { useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../Components/DeleteModal";
import { Button } from "@mui/material";
import Feedback from "./Feedback";
import { post } from "../../services/Service";
import AdminContext from "../../context/admin-context";
import {
  Get_Rating,
  Get_Ticket,
  Get_Ticket_Admin,
  Remove_Ticket,
} from "../../services/Url";
import {
  ACTIVITY_TICKET,
  FLIGHT_TICKET,
  HOTEL_TICKET,
} from "../../services/Constants";
import { OfferCard } from "../../Components/OfferCard";
import download from "../../Components/assets/images/download.png"

const ImageBase = process.env.REACT_APP_IMAGE_BASE;
const Tickets = ({
  loading,
  offerID,
  discountHandler,
  tripAmount,
  discount,
  isRated
}) => {
  const { userId, tripId } = useParams();
  const navigate = useNavigate();
  const ctx = useContext(AdminContext);

  const [flightData, setFlightData] = useState([]);
  const [hotelData, setHotelData] = useState([]);
  const [activityData, setActivityData] = useState([]);
  const [userFeedback, setUserFeedback] = useState();
  const [ticketData, setTicketData] = useState();

  useEffect(() => {
    const body = JSON.stringify({
      userid: userId,
    });
    const url = Get_Ticket_Admin + "/" + tripId;
    post(url, body, setTicketData, loading);
  }, [ctx.flightTicUpdated, ctx.activityTicUpdated, ctx.hotelTicUpdated]);

  useEffect(() => {
    const body = JSON.stringify({
      tripid: tripId,
    });
    if (isRated) {
      post(Get_Rating, body, setUserFeedback, loading);
    }
  }, []);

  useEffect(() => {
    if (ticketData?.AllTicket?.length > 0) {
      setFlightData(
        ticketData?.AllTicket?.filter((item) => item.type === FLIGHT_TICKET)
      );
      setHotelData(
        ticketData?.AllTicket?.filter((item) => item.type === HOTEL_TICKET)
      );
      setActivityData(
        ticketData?.AllTicket?.filter(
          (item) => item.type === ACTIVITY_TICKET
        )
      );
    }
  }, [ticketData]);


  const returnFlights = flightData?.filter(
    (item) => item.return_ticket === true
  );
  const departFlights = flightData?.filter(
    (item) => item.return_ticket === false
  );

  const calPayableAmnt = () => {
    const amt = (tripAmount - (tripAmount * discount) / 100).toFixed(2);
    return amt;
  };

  return (
    <div className="row mt-5 mx-4">
      <div className="col-xxl-6 pe-5">
        {/*Tickets of Flight*/}
        {flightData?.length > 0 && (
          <div className="ticket-info">
            <h4>Ticket of Flight</h4>
            {flightData?.map((item) => {
              return (
                <div className="ticket-card" key={item._id}>
                  <div className="card-info">
                    <div>
                      <h5>{item.title}</h5>
                      <div>
                        <Moment format="MMM Do YYYY, h:mm a">
                          {item.start_date}
                        </Moment>{" "}
                        <span className="fs-5 fw-bold">|</span>{" "}
                        <Moment format="MMM Do YYYY, h:mm a">
                          {item.end_date}
                        </Moment>
                      </div>
                      <h6>
                        {item.source}{" "}
                        <span className="">
                          <span className="fs-5 fw-bold">to</span>{" "}
                        </span>{" "}
                        {item.destination}
                      </h6>
                      <h4>{item.description}</h4>
                    </div>
                    <h3>${item.total_amount}</h3>
                  </div>
                  <div className="text-end">
                    {ticketData?.paid && <div className="btn-group">
                      <a
                        href={ImageBase + "uploadticket/" + item.upload_ticket}
                        download
                        target="_blank"
                        rel="noreferrer"
                        className="btn-design dwn-btn"
                      >
                        <span>
                          <img alt="img" src={download} />
                        </span>
                        Download Ticket
                      </a>
                    </div>}

                  </div>
                  {!ticketData?.paid && <div className="gallery-actions ticket-actions text-end">
                    <DeleteModal
                      id={item._id}
                      url={Remove_Ticket}
                      type="flight ticket"
                      loadingHandler={loading}
                    />
                  </div>}

                </div>
              );
            })}
          </div>
        )}

        {/*Tickets of Accomodation*/}
        {hotelData?.length > 0 && (
          <div className="ticket-info mt-4">
            <h4>Ticket of Accommodation</h4>
            {hotelData?.map((item) => {
              return (
                <div className="ticket-card" key={item._id}>
                  <div className="card-info">
                    <div>
                      <h5>{item.title}</h5>
                      <div>
                        <Moment format="MMM Do YYYY, h:mm a">
                          {item.start_date}
                        </Moment>{" "}
                        <span className="fs-5 fw-bold">|</span>{" "}
                        <Moment format="MMM Do YYYY, h:mm a">
                          {item.end_date}
                        </Moment>
                      </div>
                      <h4>{item.description}</h4>
                    </div>
                    <h3>${item.total_amount}</h3>
                  </div>
                  <div className="text-end">
                    {ticketData?.paid && <div className="btn-group">
                      <a
                        href={ImageBase + "uploadticket/" + item.upload_ticket}
                        download
                        target="_blank"
                        rel="noreferrer"
                        className="btn-design dwn-btn"
                      >
                        <span>
                          <img alt="img" src={download} />
                        </span>
                        Download Ticket
                      </a>
                    </div>}

                  </div>
                  <div className="gallery-actions ticket-actions text-end">
                    <DeleteModal
                      id={item._id}
                      url={Remove_Ticket}
                      type="hotel ticket"
                      loadingHandler={loading}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {/*Tickets of Activity*/}
        {activityData?.length > 0 && (
          <div className="ticket-info mt-4">
            <h4>Ticket of Activities</h4>
            {activityData?.map((item) => {
              return (
                <div className="ticket-card" key={item._id}>
                  <div className="card-info">
                    <div>
                      <h5>{item.title}</h5>
                      <div>
                        <Moment format="MMM Do YYYY, h:mm a">
                          {item.start_date}
                        </Moment>{" "}
                        <span className="fs-5 fw-bold">|</span>{" "}
                        <Moment format="MMM Do YYYY, h:mm a">
                          {item.end_date}
                        </Moment>
                      </div>
                      <h4>{item.description}</h4>
                    </div>
                    <h3>${item.total_amount}</h3>
                  </div>
                  <div className="text-end">
                    {ticketData?.paid && <div className="btn-group">
                      <a
                        href={ImageBase + "uploadticket/" + item.upload_ticket}
                        download
                        target="_blank"
                        rel="noreferrer"
                        className="btn-design dwn-btn"
                      >
                        <span>
                          <img alt="img" src={download} />
                        </span>
                        Download Ticket
                      </a>
                    </div>}

                  </div>
                  <div className="gallery-actions ticket-actions text-end">
                    <DeleteModal
                      id={item._id}
                      url={Remove_Ticket}
                      type="activity ticket"
                      loadingHandler={loading}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      {/*Tickets of Info Part*/}
      <div className="col-xxl-6 mt-xxl-0 mt-4 ps-5">
        {(flightData?.length !== 0 ||
          hotelData?.length !== 0 ||
          activityData?.length !== 0) && (
            <div className="ticket-info">
              <h4>Information</h4>
              <div className="info-bg">
                <ul className="ticket-depart">
                  {departFlights?.length > 0 && (
                    <>
                      <li className="text-center">
                        <div className="trip-info-hdg">
                          <h6>{departFlights[0]?.source}</h6>
                          <div className="flight-icon">
                            <img src={AeroIcon} alt="img" />
                          </div>
                          <h6>{departFlights[0]?.destination}</h6>
                        </div>
                        <div className="trip-dtl">
                          <div className="blue-txt">Start Date | End Date</div>
                          <div className="time-trip">
                            <Moment format="MMM Do YYYY, h:mm a">
                              {departFlights[0]?.start_date}
                            </Moment>{" "}
                            <span className="fs-5 fw-bold">|</span>{" "}
                            <Moment format="MMM Do YYYY, h:mm a">
                              {departFlights[0]?.end_date}
                            </Moment>
                          </div>
                        </div>
                      </li>
                      <li>
                        <h4>Flight</h4>
                        {departFlights?.map((item) => {
                          return (
                            <p key={item._id}>
                              <span>
                                <img
                                  alt="img"
                                  src={departIcon}
                                  className="icon-md"
                                />
                              </span>
                              {item.title}
                            </p>
                          );
                        })}
                      </li>
                    </>
                  )}

                  {hotelData?.length > 0 && (
                    <li>
                      <h4>Accommodation</h4>
                      {hotelData?.map((item) => {
                        return (
                          <p key={item._id}>
                            <span>
                              <img alt="img" src={hotelIcon} />
                            </span>
                            {item.title}
                          </p>
                        );
                      })}
                    </li>
                  )}
                  {activityData?.length > 0 && (
                    <li>
                      <h4>Activities</h4>
                      {activityData?.map((item) => {
                        return (
                          <p key={item._id}>
                            <span>
                              <img
                                alt="img"
                                src={activityIcon}
                                className="icon-sm"
                              />
                            </span>
                            {item.title}
                          </p>
                        );
                      })}
                    </li>
                  )}
                </ul>
                <ul className="ticket-return">
                  {returnFlights.length > 0 && (
                    <>
                      <li className="text-center">
                        <div className="trip-info-hdg">
                          <h6>{returnFlights[0].source}</h6>
                          <div className="flight-icon">
                            <img src={AeroIcon} alt="img" />
                          </div>
                          <h6>{returnFlights[0].destination}</h6>
                        </div>
                        <div className="trip-dtl">
                          <div className="blue-txt">Start Date | End Date</div>
                          <div className="time-trip">
                            <Moment format="MMM Do YYYY, h:mm a">
                              {returnFlights[0]?.start_date}
                            </Moment>{" "}
                            <span className="fs-5 fw-bold">|</span>{" "}
                            <Moment format="MMM Do YYYY, h:mm a">
                              {returnFlights[0]?.end_date}
                            </Moment>
                          </div>
                        </div>
                      </li>
                      <li>
                        <h4>Flight</h4>
                        {returnFlights?.map((item) => {
                          return (
                            <p key={item._id}>
                              <span>
                                <img
                                  alt="img"
                                  src={departIcon}
                                  className="icon-md"
                                />
                              </span>
                              {item.title}
                            </p>
                          );
                        })}
                      </li>
                    </>
                  )}
                </ul>
                <div className="bill-info">
                  <h3 className="px-3">
                    <span>Total Amount:</span> <span>${tripAmount} </span>
                  </h3>
                  {
                    <>
                      <h3 className="px-3">
                        <span>Discount:</span>{" "}
                        <span>{discount ? discount + "%" : "0"}</span>
                      </h3>
                      <h3 className="px-3">
                        <span>Payable Amount:</span>{" "}
                        <span>${discount ? calPayableAmnt() : tripAmount}</span>{" "}
                      </h3>
                      <div className="pay-btns mt-4 px-3">
                        {/*payment done via card*/}
                        {ticketData?.paid === true &&
                          ticketData?.userpaymnt && (
                            <>
                              <a
                                href={
                                  ticketData?.userpaymnt?.transactionReceipt
                                }
                                download
                                target="_blank"
                                rel="noreferrer"
                                className="btn-design dwn-btn"
                              >
                                <span>
                                  <img alt="img" src={download} />
                                </span>
                                Download Receipt
                              </a>
                              <button
                                className="btn-design approve-btn"
                                style={{ cursor: "default" }}
                              >
                                Paid
                              </button>
                            </>
                          )}

                        {/*Payment is done via bank transfer but yet to be approved*/}
                        {ticketData?.paid === false &&
                          ticketData?.bankerResult && (<>
                            {/*Yet to be approved*/}
                            {ticketData?.bankerResult?.approved === null && (
                              <>
                                <Button
                                  onClick={() =>
                                    navigate(`/receipt/${tripId}/${ticketData?.bankerResult?._id}`)
                                  }
                                  className="btn-design"
                                >
                                  View Bank Receipt
                                </Button>
                              </>
                            )}
                            {/*Rejected by admin*/}
                            {ticketData?.bankerResult?.approved === false && (
                              <>
                                <Button className="btn-danger">Rejected</Button>
                                <a
                                  href={
                                    ticketData?.bankerResult?.bank_receipt
                                  }
                                  download
                                  target="_blank"
                                  rel="noreferrer"
                                  className="btn-design view-btn"
                                >
                                  <span>
                                    <img alt="img" src={download} />
                                  </span>
                                  Download Receipt
                                </a>
                              </>
                            )}
                          </>)}

                        {/*Payment is done via bank transfer approved by admin*/}
                        {ticketData?.paid === true &&
                          ticketData?.bankerResult &&
                          ticketData?.bankerResult?.approved === true && (
                            <>
                              <a
                                href={
                                  ImageBase +
                                  "receipt/" +
                                  ticketData.bankerResult?.bank_receipt
                                }
                                download
                                target="_blank"
                                rel="noreferrer"
                                className="btn-design dwn-btn"
                              >
                                <span>
                                  <img alt="img" src={download} />
                                </span>
                                Download Receipt
                              </a>
                              <button
                                className="btn-design approve-btn"
                                style={{ cursor: "default" }}
                              >
                                Approved
                              </button>
                            </>
                          )}

                        {/* Payment Not done yet */}
                        {ticketData?.paid === false &&
                          (ticketData?.userpaymnt === null ||
                            ticketData?.userpaymnt === undefined) &&
                          (ticketData?.bankerResult === null ||
                            ticketData?.bankerResult === undefined) && (
                            <Button className="btn-unpaid">Pending</Button>
                          )}

                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
          )}
        {offerID && (
          <OfferCard
            offerID={offerID}
            isloading={loading}
            discountHandler={discountHandler}
          />
        )}
        {userFeedback && <Feedback ratingData={userFeedback} />}
      </div>
    </div>
  );
};

export default Tickets;

import React from 'react'
import { Box, TextField } from '@mui/material'
import SearchIcon from "@mui/icons-material/Search";

const Search = (props) => {

    const searchTextHandler = (e) => {
        props.searchHandler(e.target.value);
    }

    return (
        <div className='search'>
            <Box className="table_srch">
                <TextField
                    placeholder="Search..."
                    InputProps={{
                        startAdornment: <SearchIcon />,
                    }}
                    onChange={searchTextHandler}
                />
            </Box>
        </div>
    )
}

export default Search
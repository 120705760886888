import * as React from "react";
import { Button, Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Link } from "react-router-dom";
import logoutIcon from "../Components/assets/images/logoutIcon.png";
import logoutActive from "../Components/assets/images/logout-active.png";
import { toast } from "react-toastify";

export default function LogoutModal(props) {
  const [modalOpen, setModalOpen] = React.useState(false);

  const handleClickOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    localStorage.removeItem("token");
    toast.success("Logout Successfully");
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        <span>
          <img src={logoutIcon} alt="icon" />
          <img src={logoutActive} alt="icon" />
        </span>
        {!props.toggler && 'Logout'}
      </Button>
      <Dialog
        className="custom_modal"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="modal-img"><img src={logoutActive} alt="img" /></div>
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box className="btn_wpr">
            <Button onClick={() => setModalOpen(false)}>
              Cancel
            </Button>
            <Link
              to="/login"
              onClick={handleClose}
              autoFocus
            >
              Confirm
            </Link>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}

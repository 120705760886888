import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { Loader } from "../../Components/Loader";
import { useNavigate } from "react-router-dom";
import editIcon from "../../Components/assets/images/edit-icon.svg";
import PlaceholderImg from "../../Components/assets/images/placeholder-img.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addData } from "../../services/Service";
import { Create_Offer } from "../../services/Url";
import { handleFileChange } from "../gallery/Gallery";
import { toast } from "react-toastify";

const OfferAdd = () => {
  const navigate = useNavigate();
  const [isloading, setIsloading] = useState(false);
  const [coverImg, setCoverImg] = useState(PlaceholderImg);

  const validationSchema = Yup.object({
    title: Yup.string()
      .required("Title is required")
      // .matches(/^[a-zA-Z\s]+$/, "Title can only contain characters")
      .min(5, "Title must be at least 5 characters")
      .max(50, "Title can't exceed 50 characters"),
    description: Yup.string()
      .required("Description is required")
      .min(5, "Description must be at least 5 characters")
      .max(100, "Description can't exceed 100 characters"),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      image: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("cover_image", coverImg);
      formData.append("description", values.description);
      formData.append("title", values.title);
      setTimeout(() => {
        addData(Create_Offer, formData, setIsloading, "Offer Added Successfully!");
        navigate("/offermanagement");
      }, 1000);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (coverImg === undefined || coverImg === PlaceholderImg || coverImg === null) {
      toast.error("Image is required!");
    } else {
      formik.handleSubmit(e);
    }
  }

  return (
    <Box
      display="flex"
      className="box-wrapper br-24 table_bx  pb-5"
      flexDirection="column"
      justifyContent="start"
    >
      {isloading ? <Loader /> : ""}
      <div className="heading-btn_wrapper">
        <h5
          style={{ display: "inline-flex", cursor: "pointer" }}
          className="fz-24 fw-500"
        >
          Add Offer
        </h5>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row px-4">
          <div className="col-6 mb-5 mx-auto">
            <Box className="cover-img-box">
              <img src={(coverImg === PlaceholderImg ? coverImg : coverImg === null ? PlaceholderImg : URL.createObjectURL(coverImg))} alt="img" className="pro-img" />
              <label htmlFor="icon-button-file">
                <TextField
                  inputProps={{
                    accept: "image/jpeg, image/jpg, image/png",
                  }}
                  id="icon-button-file"
                  type="file"
                  name="image"
                  sx={{ display: "none" }}
                  onChange={(e) => {
                    handleFileChange(e, setCoverImg)
                  }}
                />
                <div className="edit-icon">
                  <img src={editIcon} alt="edit icon" />
                </div>
              </label>
            </Box>
          </div>
        </div>
        <div className="row">
          <div className="col-6 mx-auto px-4">
            <Box className="inpt_bx">
              <Typography variant="h6">Title</Typography>
              <TextField
                fullWidth
                name="title"
                id="title"
                placeholder="Title"
                {...formik.getFieldProps("title")}
                error={formik.touched.title && formik.errors.title}
                helperText={formik.touched.title && formik.errors.title}
                className={
                  formik.touched.title && formik.errors.title
                    ? "error-border"
                    : ""
                }
              />
            </Box>
            <Box className="inpt_bx mt-4">
              <Typography variant="h6">Description</Typography>
              <textarea
                placeholder="Type here..."
                rows="6"
                name="description"
                id="description"
                {...formik.getFieldProps("description")}
                className={
                  formik.touched.description && formik.errors.description
                    ? "error-border"
                    : ""
                }
              />
              {formik.touched.description && formik.errors.description && (
                <div className="error-text">{formik.errors.description}</div>
              )}
            </Box>
            <div className="inpt_bx text-center mt-4">
              <Button type="submit" className="btn btn-design">
                Submit
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Box>
  );
};

export default OfferAdd;

import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Delete_Holiday, Holiday_Package } from "../../services/Url";
import { Loader } from "../../Components/Loader";
import { post, searchData } from "../../services/Service";
import Search from "../../Components/Search";
import editIcon from "../../Components/assets/images/edit-icon.svg";
import editIconActive from "../../Components/assets/images/edit-white.svg";
import DeleteModal from "../../Components/DeleteModal";
import dummyImg from "../../Components/assets/images/placeholder-img.png";
import AdminContext from "../../context/admin-context";


const ImageBase = process.env.REACT_APP_IMAGE_BASE;
export default function HolidayPackage() {
  const [isloading, setIsloading] = useState(true);
  const [holidayData, setHolidayData] = useState();
  const [searchText, setSearchText] = useState("");
  const [error, setError] = useState(false);
  const [page, setPage] = useState(1);
  const ctx = useContext(AdminContext);
  const navigate = useNavigate();

  let pageData = JSON.stringify({
    search_string: "",
    page: page,
  });

  useEffect(() => {
    setTimeout(() => {
      post(Holiday_Package, pageData, setHolidayData, setIsloading);
    }, 1500);
  }, []);

  useEffect(() => {
    if (!/^[a-zA-Z0-9]/.test(searchText[0])) {
      //first character shouldn't be special character
      setError(true);
      return;
    }
    setError(false);
    const delay = 500;
    let timerId;
    let pageData = JSON.stringify({
      search_string: searchText,
      page: page,
    });
    const delayedSearch = () => {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        // Make API call here
        searchData(Holiday_Package, pageData, setHolidayData);
      }, delay);
    };

    delayedSearch();
    return () => {
      clearTimeout(timerId);
    };
  }, [searchText]);

  const pagingHandler = (event, value) => {
    setPage(value);
    let pageData = {
      search_string: searchText,
      page: value,
    };
    pageData = JSON.stringify(pageData);
    post(Holiday_Package, pageData, setHolidayData, setIsloading);
  };

  useEffect(() => {
    //to hit api whenever an item is deleted
    setTimeout(() => {
      post(Holiday_Package, pageData, setHolidayData, setIsloading);
    }, 1500);
  }, [ctx.isDeleted, pageData]);

  const tableData = () => {
    return (
      <TableContainer className="tbl_mn br-24 br-btm-0 ">
        <Box className="table-responsive table-line">
          <Table className="table-design table_center offer_table-lg">
            <TableHead>
              <TableRow className="tbl_rw">
                <TableCell className="fw-bold text-center">
                  Cover Image
                </TableCell>
                <TableCell className="fw-bold">Title</TableCell>
                <TableCell className="fw-bold">Description </TableCell>
                <TableCell className="fw-bold nowarp">Price </TableCell>
                <TableCell className="fw-bold nowarp">Stay Duration </TableCell>
                <TableCell className="fw-bold text-center"> Action </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {holidayData?.docs?.length > 0 && !error ? (
                holidayData?.docs?.map((item) => {
                  return (
                    <TableRow key={item._id}>
                      <TableCell className="fz-16 text-center" scope="row">
                        <div className="cover-img">
                          <img
                            src={
                              item.cover_image
                                ? ImageBase + "coverimage/" + item.cover_image
                                : dummyImg
                            }
                            alt="img"
                          />
                        </div>
                      </TableCell>
                      <TableCell className="fz-16">{item.title}</TableCell>
                      <TableCell className="fz-16">
                        {item.description}
                      </TableCell>
                      <TableCell className="fz-16 nowrap">${item.price}</TableCell>
                      <TableCell className="fz-16 nowrap">{item.stayDuration}</TableCell>
                      <TableCell className="fz-16 table-actions text-center">
                        <Link to={`/holidayedit/${item._id}`}>
                          <img src={editIcon} alt="edit-icon" />
                          <img src={editIconActive} alt="edit-icon" />
                        </Link>
                        <DeleteModal
                          id={item._id}
                          url={Delete_Holiday}
                          type="Holiday package"
                          loadingHandler={setIsloading}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={12} className="empty-text text-center">
                    No data found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "end",
            marginTop: "30px",
          }}
        >
          {holidayData?.docs?.length > 0 ? (
            <Stack spacing={2}>
              <Pagination
                count={holidayData?.totalPages}
                onChange={pagingHandler}
              />
            </Stack>
          ) : (
            ""
          )}
        </Box>
      </TableContainer>
    );
  };

  return (
    <Box
      display="flex"
      className="box-wrapper br-24 table_bx"
      flexDirection="column"
      justifyContent="start"
    >
      {isloading ? <Loader /> : ""}
      <div className="heading-btn_wrapper">
        <h5
          style={{ display: "inline-flex" }}
          className="fz-24 fw-500 mb-0"
        >
          Holiday Packages Management
        </h5>
        <div className="d-flex gap-4 align-items-center w-40">
          <Search searchHandler={setSearchText} />
          <Button
            className="btn btn-design"
            onClick={() => navigate(`/holidayadd`)}
          >
            Add
          </Button>
        </div>
      </div>
      {tableData()}
    </Box>
  );
}

export const ENABLE = 'ENABLE';
export const DISABLE = 'DISABLE';

//Gallery Tabs
export const ALL = 'all';
export const HOTEL = 'hotel';
export const FLIGHT = 'flight';
export const ACTIVITY = 'activity';

// Trip tabs
export const ALL_TRIP = 'all_trip';
export const NEW_TRIP = 'new_trip';

//Tickets tabs
export const FLIGHT_TICKET = 'Flight Ticket';
export const ACTIVITY_TICKET = 'Activity Ticket';
export const HOTEL_TICKET = 'Accommodation Ticket';

// CMS
export const TERMS = "term & condition";
export const PRIVACY = "privacy policy";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  FormControl,
  TextField,
  Box,
  IconButton,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  Button,
} from "@mui/material";
import logo from "../../Components/assets/images/logo_color.png";
import LoginImg from "../../Components/assets/images/destination1.png";
import { toast } from "react-toastify";
import { Signin } from "../../services/Url";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const BaseUrl = process.env.REACT_APP_BASE_URL;
function Login() {
  const navigate = useNavigate();
  const auth = localStorage.getItem("token");
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
    setShowPassword((show) => !show)
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Invalid email"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      fetch(`${BaseUrl + Signin}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: values.email,
          password: values.password,
        }),
      })
        .then(async (response) => {
          const data = await response.json();
          if (response?.status === 200) {
            localStorage.setItem("user", JSON.stringify(data));
            localStorage.setItem("token", data.data);
            toast.dismiss();
            toast.success("Login successful");
            navigate("/");
          } else if (response?.status === 401) {
            toast.dismiss();
            toast.error("Please Enter Valid Credentials");
            window.location.replace("/");
          } else if (response?.status === 404) {
            toast.dismiss();
            toast.error("Please Enter Valid Credentials");
          } else {
            toast.dismiss();
            toast.error("Please Enter Valid Credentials");
          }
        })
        .catch((error) => {
          toast.dismiss();
          toast.error(error);
        });
    },
  });

  useEffect(() => {
    if (auth) {
      navigate("/");
    }
  }, [auth]);

  return (
    <Box className="lgn_mdl_wpr">
      <div>
        <div className="lgn_left">
          <img src={LoginImg} alt="img" />
        </div>
        <div className="lgn_right">
          <form

            onSubmit={formik.handleSubmit}
            className="lgn_mdl"
            style={{
              margin: "0 auto",
              padding: "40px",
            }}
          >
            <Box >
              <div className="logo">
                <img src={logo} alt="popular" loading="lazy" />
              </div>
              <h4>Login</h4>
              <Box
                display="flex"
                width="100%"
                flexDirection="column"
                gap={2}
                marginBottom={4}
              >
                <FormControl sx={{ mb: 1 }}>
                  <TextField
                    name="email"
                    type="email"
                    label="Enter Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && formik.errors.email}
                    helperText={formik.touched.email && formik.errors.email}

                  />
                </FormControl>
                <FormControl
                  variant="outlined"
                  className={
                    formik.touched.password &&
                    formik.errors.password &&
                    "pass-err"
                  }
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Enter Password
                  </InputLabel>
                  <OutlinedInput
                    name="password"
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.password && formik.errors.password}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className="error-text">{formik.errors.password}</div>
                  )}
                </FormControl>
                <Link to="/forgotpassword" className="frgt-pass">
                  Forgot Password ?
                </Link>
              </Box>
              <div className="d-flex justify-content-center align-items-center">
                <Button type="submit" className="login-btn">
                  Login
                </Button>
              </div>
            </Box>

          </form>
        </div>
      </div>
    </Box>
  );
}

export default Login;

import React, { useState } from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import { Edit_Contact } from "../../services/Url";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { addData } from "../../services/Service";

const validationSchema = Yup.object().shape({
    location: Yup.string()
        .min(3, "Location must be at least 3 characters")
        .required("Location is required"),
    email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
    phoneNumber: Yup.string()
        .matches(/^[0-9+]*$/, "Phone number must contain numbers and the plus sign only")
        .required("Phone number is required"),
});

const EditContact = () => {
    const location = useLocation();
    const { state } = location;
    const [isLoading, setIsLoading] = useState(false);
    const formik = useFormik({
        initialValues: {
            location: state?.address,
            email: state?.email,
            phoneNumber: state?.phone_number
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const body = JSON.stringify({
                id: state?._id,
                address: values.location,
                email: values.email,
                phone_number: values.phoneNumber,
            });
            addData(Edit_Contact, body, setIsLoading, "Contact Details Updated Successfully!");
        },
        enableReinitialize: true,
    });


    return (
        <Box className="changePass_cont">
            <Box className="box-wrapper">
                <div className="heading-btn_wrapper mb-3">
                    <h5 className="fz-24 fw-500">Edit Contact Details</h5>
                </div>
                <div className="row">
                    <div className="col-6">
                        <Box className="form_wrapper pwd_form px-4">
                            <Box className="inpt_bx mb-4">
                                <Typography variant="h6">Office Location</Typography>
                                <TextField
                                    placeholder="Enter office location"
                                    fullWidth
                                    name="location"
                                    type={"text"}
                                    variant="standard"
                                    {...formik.getFieldProps('location')}
                                    error={formik.touched.location && Boolean(formik.errors.location)}
                                    helperText={formik.touched.location && formik.errors.location}
                                />
                            </Box>
                            <Box className="inpt_bx mb-4">
                                <Typography variant="h6">Email Address</Typography>
                                <TextField
                                    placeholder="Enter email address"
                                    fullWidth
                                    name="email"
                                    type={"email"}
                                    variant="standard"
                                    {...formik.getFieldProps('email')}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}

                                />
                            </Box>
                            <Box className="inpt_bx">
                                <Typography variant="h6">Phone Number</Typography>
                                <TextField
                                    placeholder="Enter phone number"
                                    fullWidth
                                    name="phoneNumber"
                                    type={"tel"}
                                    variant="standard"
                                    {...formik.getFieldProps('phoneNumber')}
                                    error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                    helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                                />
                            </Box>
                            <Box>
                                <Button
                                    className="btn btn-design"
                                    sx={{ mt: 4, mb: 3 }}
                                    type="submit"
                                    onClick={formik.handleSubmit}
                                >
                                    Save
                                </Button>
                            </Box>
                        </Box>
                    </div>
                </div>
            </Box>
        </Box>
    );
};

export default EditContact;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FormControl, TextField, Typography, Box, Button } from "@mui/material";
import logo from "../../Components/assets/images/logo_color.png";
import LoginImg from "../../Components/assets/images/destination1.png";
import { toast } from "react-toastify";
import { Forgot_Password } from "../../services/Url";
import * as Yup from "yup";
import { useFormik } from "formik";

const BaseUrl = process.env.REACT_APP_BASE_URL;

function ForgotPassword() {
  const [status, setStatus] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Invalid email"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      toast.dismiss();
      fetch(`${BaseUrl + Forgot_Password}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: values.email,
        }),
      })
        .then(async (response) => {
          const data = await response.json();
          if (response?.status === 200) {
            setStatus(true);
            toast.success(data.response);
          } else {
            toast.error(data.message);
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    },
  });

  return (
    <Box className="lgn_mdl_wpr">
      <div>
        <div className="lgn_left">
          <img src={LoginImg} alt="img" />
        </div>
        <div className="lgn_right">
          <form
            onSubmit={formik.handleSubmit}
            className="lgn_mdl"
            style={{
              width: " 500px ",
              margin: "0 auto",
              padding: "30px",
            }}
          >
            <Box>
              <div className="logo">
                <img src={logo} alt="popular" loading="lazy" />
              </div>
              {!status ? (
                <>
                  <h4>Forgot Password</h4>
                  <Box
                    display="flex"
                    width="100%"
                    flexDirection="column"
                    gap={2}
                    marginBottom={4}
                  >
                    <FormControl sx={{ mb: 1 }}>
                      <TextField
                        name="email"
                        type="email"
                        label="Enter Email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.email && formik.errors.email}
                        helperText={formik.touched.email && formik.errors.email}
                      />
                      <div className="d-flex mt-4 justify-content-center">
                        <Button type="submit" className="login-btn">
                          Submit
                        </Button>
                      </div>
                      <p className="login-link text-center mt-4">
                        Already have an account?{" "}
                        <span>
                          <Link to={-1}>Login here</Link>
                        </span>
                      </p>
                    </FormControl>
                  </Box>
                </>
              ) : (
                <><Typography className='fz-16' marginBottom={4} align="center">
                  Email Sent Successfully to your Account Please check and Reset
                  Your Password

                </Typography>
                  <p className="login-link text-center mt-4">
                    <span>
                      <Link to={'/login'}>Login</Link>
                    </span>
                  </p></>
              )}
            </Box>
          </form>
        </div>
      </div>
    </Box>
  );
}

export default ForgotPassword;

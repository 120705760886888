import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "../pages/dashboard/Dashboard";
import OfferManagement from "../pages/offer/OfferManagement";
import ChangePassword from "../pages/auth/ChangePassword";
import Login from "../pages/auth/Login";
import TripManagement from "../pages/tripManagement/TripManagement";
import Error from "../pages/error/Error";
import Maincontainer from "./Maincontainer";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ResetPassword from "../pages/auth/ResetPassword";
import OfferAdd from "../pages/offer/OfferAdd";
import OfferEdit from "../pages/offer/OfferEdit";
import HolidayPackage from "../pages/holiday/HolidayPackage";
import HolidayEdit from "../pages/holiday/HolidayEdit";
import HolidayAdd from "../pages/holiday/HolidayAdd";
import UserManagement from "../pages/users/UserManagement";
import UserEdit from "../pages/users/UserEdit";
import Terms from "../pages/terms/Terms";
import Privacy from "../pages/privacy/Privacy";
import ContactUs from "../pages/contact/ContactUs";
import EditContact from "../pages/contact/EditContact";
import UserTripDetail from "../pages/users/UserTripDetail";
import TripView from "../pages/tripManagement/TripView";
import Gallery from "../pages/gallery/Gallery";
import Receipt from "../pages/tripManagement/Receipt";
import { Chat } from "../pages/chat/Chat";
import PrivacyView from "../pages/privacy/PrivacyView";
import TermView from "../pages/terms/TermView";
import SubsManagement from "../pages/subscription/SubsManagement";
import Itinerary from "../pages/itinerary/Itinerary";
import ItineraryView from "../pages/itinerary/ItineraryView";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={`/`} element={<Maincontainer />}>
          <Route index element={<Dashboard />} />
          {/*Offer*/}
          <Route path={"/offermanagement"} element={<OfferManagement />} />
          <Route path="/offeradd" element={<OfferAdd />} />
          <Route path="/offeredit/:id" element={<OfferEdit />} />
          {/*Holiday */}
          <Route path="/holidaypackage" element={<HolidayPackage />} />
          <Route path="/holidayadd" element={<HolidayAdd />} />
          <Route path="/holidayedit/:id" element={<HolidayEdit />} />
          {/*Gallery*/}
          <Route path="/gallery" element={<Gallery />} />
          {/*Chat*/}
          <Route path="/chat" element={<Chat />} />
          {/*User Manangement*/}
          <Route path="/usermanagement" element={<UserManagement />} />
          <Route path="/useredit/:id" element={<UserEdit />} />
          <Route path="/userdetail/:id" element={<UserTripDetail />} />
          {/*Trip Management*/}
          <Route path="/tripmanagement" element={<TripManagement />} />
          <Route path="/viewtrip/:userId/:tripId" element={<TripView />} />
          <Route path="/receipt/:tripId/:receiptId" element={<Receipt />} />
          {/*Terms & Conditon Policy */}
          <Route path="/term&condition" element={<Terms />} />
          <Route path="/viewterms" element={<TermView />} />
          {/*Privacy Policy */}
          <Route path="/privacypolicy" element={<Privacy />} />
          <Route path="/viewpolicy" element={<PrivacyView />} />
          {/*Contact us */}
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/editcontact" element={<EditContact />} />
          {/*Subscriber */}
          <Route path="/emailsubscriber" element={<SubsManagement />} />
          {/*Change Password */}
          <Route path="/changepassword" element={<ChangePassword />} />
          {/*Trip Itinerary */}
          <Route path="/tripItinerary" element={<Itinerary />} />
          <Route path="/viewTripItinerary/:id" element={<ItineraryView />} />
        </Route>
        <Route path="*" element={<Error />} />
        <Route path={"/login"} element={<Login />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/resetpassword/:tokenId" element={<ResetPassword />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;

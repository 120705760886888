import React, { useContext, useState } from "react";
import { Box, Button, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../../Components/Loader";
import dummyProfile from "../../Components/assets/images/profilePicture.png";
import TicketForm from "./TicketForm";
import Tickets from "./Tickets";
import { useEffect } from "react";
import { getData } from "../../services/Service";
import Moment from "react-moment";
import {
  Get_User_Detail,
  Individual_Trip_Details,
} from "../../services/Url";
import AdminContext from "../../context/admin-context";

const ImageBase = process.env.REACT_APP_IMAGE_BASE;
const longText = `The Trip Has Expired`;

function InfoBox() {
  return (
    <div>
      <Tooltip title={longText}>
        <Button sx={{ m: 1 }}>
          <InfoIcon />
        </Button>
      </Tooltip>
    </div>
  );
}

const TripView = () => {
  const [isloading, setIsloading] = useState(true);
  const [data, setData] = useState();
  const [userDetail, setUserDetail] = useState();
  const { userId, tripId } = useParams();
  const [isAllTicketAdded, setIsAllTicketAdded] = useState(false);
  const [totalAmount, setTotalAmount] = useState();
  const [discount, setDiscount] = useState();
  const today = new Date();

  const ctx = useContext(AdminContext);

  const navigate = useNavigate();
  let url = `${userId}/${tripId}`;

  useEffect(() => {
    getData(Get_User_Detail, userId, setUserDetail, setIsloading);
  }, []);

  useEffect(() => {
    getData(Individual_Trip_Details, url, setData, setIsloading);
  }, [
    ctx.flightTicUpdated,
    ctx.activityTicUpdated,
    ctx.hotelTicUpdated,
    isAllTicketAdded,
  ]);
  useEffect(() => {
    ticketAddedHandler();
    setTotalAmount(data?.totalTicketAmount);
  }, [data]);

  const ticketAddedHandler = () => {
    if (data?.isAllTicketAdded) {
      setIsAllTicketAdded(true);
    } else {
      setIsAllTicketAdded(false);
    }
  };

  const formattedAges = data?.age ? data.age.join(", ") : "";
  const flights = data?.list_of_Preference
    ? data.list_of_Preference[0].flight.join(", ")
    : "";
  const hotels = data?.list_of_Preference
    ? data.list_of_Preference[0].accommodation.join(", ")
    : "";
  const activities = data?.list_of_Preference
    ? data.list_of_Preference[0].activities.join(", ")
    : "";
  const tripDetails = [
    { label: "Booking ID", value: `${data?._id}` },
    { label: "Start Date", value: `${data?.trip_start_date}` },
    { label: "End Date", value: `${data?.trip_end_date}` },
    {
      label: "Number of Passengers",
      value: `${data?.number_of_passenger}`,
    },
    { label: "Age of Passengers", value: formattedAges },
    { label: "Source", value: `${data?.source}` },
    {
      label: "Destination",
      value: `${data?.destination}`,
    },
    {
      label: "Total Amount",
      value: data?.totalTicketAmount ? `$${data?.totalTicketAmount}` : "$0",
    },
    { label: "Flight", value: flights },
    { label: "Accommodation", value: hotels },
    { label: "Activities", value: activities },
    {
      label: "Offer Applied",
      value: data?.offerId ? "Applied" : "Not applied",
    },
  ];


  const chatData = {
    chatId: tripId,
    type: "trip",
    userId: userId
  }

  return (
    <Box
      display="flex"
      className="box-wrapper br-24 table_bx"
      flexDirection="column"
      justifyContent="start"
    >
      {isloading ? <Loader /> : ""}
      <div className="heading-btn_wrapper">
        <h5
          style={{ display: "inline-flex", cursor: "pointer" }}
          className="fz-24 fw-500"
        >
          Trip Management
        </h5>
        <Button className="btn btn-design" onClick={() => navigate(`/chat`, { state: { chatData } })}>
          Chat
        </Button>
      </div>
      <div className="trip-box">
        <div className="row">
          <div className="col-xxl-4">
            {" "}
            <div className="user-info">
              <div className="user-img">
                <img
                  src={
                    userDetail?.profile_picture
                      ? ImageBase + "profile/" + userDetail?.profile_picture
                      : dummyProfile
                  }
                  alt="user-img"
                />
              </div>
              <div className="user-detail">
                <h4>{userDetail?.name}</h4>
                <p>{userDetail?.email}</p>
                <p>
                  {userDetail?.country_code} {userDetail?.phone_number}
                </p>
              </div>
            </div>
          </div>
          <div className="col-xxl-8 mt-xxl-0 mt-5">
            <div className="trip-detail">
              <h3>Trip Detail</h3>
              <ul>
                {tripDetails?.map((item, index) => {
                  return (
                    <li key={index}>
                      <h6>{item.label}</h6>
                      <div>
                        {item.label === "Start Date" ||
                          item.label === "End Date" ? (
                          today.getTime() > new Date(data?.trip_end_date).getTime() ? (
                            <div className="date-field">
                              <Moment format="DD/MM/YYYY">{item.value}</Moment>
                              <InfoBox />
                            </div>
                          ) : (
                            <Moment format="DD/MM/YYYY">{item.value}</Moment>
                          )
                        ) : (
                          item.value
                        )}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {!isAllTicketAdded && (
        <TicketForm
          loading={setIsloading}
          source={data?.source}
          destination={data?.destination}
          isTicAllHandler={setIsAllTicketAdded}
          totalAmount={totalAmount}
          discount={discount}
        />
      )}
      <Tickets
        loading={setIsloading}
        offerID={data?.offerId}
        discountHandler={setDiscount}
        tripAmount={totalAmount}
        discount={discount}
        isRated={data?.isRating}
      />
    </Box>
  );
};

export default TripView;

import { CircularProgress, Box, Modal } from "@mui/material";
import React from "react";

export const Loader = () => {
  return (
    <Box>
        <Modal open>
          <Box className="loader_loading">
            <CircularProgress color="secondary" />
          </Box>
        </Modal>
    </Box>
  );
};

import React, { useState, useEffect } from "react";
import {
    Box,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import {
    Subscriber_List,
} from "../../services/Url";
import { Loader } from "../../Components/Loader";
import { post, searchData } from "../../services/Service";
import Search from "../../Components/Search";
import Moment from "react-moment";

export default function SubsManagement() {
    const [usersList, setUsersList] = useState();
    const [isloading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [page, setPage] = useState(1);

    const pageData = JSON.stringify({
        search_string: '',
        page: page,
    });

    useEffect(() => {
        //getting users list
        setTimeout(() => {
            post(Subscriber_List, pageData, setUsersList, setIsLoading);
        }, 1500);
    }, []);

    useEffect(() => {
        //search API
        if (!/^[a-zA-Z0-9]/.test(searchText[0])) {
            //first character shouldn't be special character
            setError(true);
            return;
        }
        setError(false);
        const delay = 500;
        let timerId;

        const pageData = JSON.stringify({
            search_string: searchText,
            page: page,
        });
        const delayedSearch = () => {
            clearTimeout(timerId);
            timerId = setTimeout(() => {
                // Make API call here
                if (searchText) {
                    searchData(Subscriber_List, pageData, setUsersList);
                }
            }, delay);
        };

        delayedSearch();

        return () => {
            clearTimeout(timerId);
        };
    }, [searchText]);

    const pagingHandler = (event, value) => {
        setPage(value);
        const pageData = JSON.stringify({
            search_string: searchText,
            page: page,
        });
        post(Subscriber_List, pageData, setUsersList, setIsLoading);
    };

    const tableData = () => {
        return (
            <TableContainer className="tbl_mn br-24 br-btm-0 ">
                <Box className="table-responsive table-line">
                    <Table className="table-design table_center user-table-lg">
                        <TableHead>
                            <TableRow className="tbl_rw">
                                <TableCell className="fw-bold">Email </TableCell>
                                <TableCell className="fw-bold">Date Subscribed </TableCell>
                                <TableCell className="fw-bold">Updated At</TableCell>
                                <TableCell className="fw-bold">Is Subscribed</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {usersList?.docs?.length > 0 && !error ? (
                                usersList?.docs?.map((item) => {
                                    return (
                                        <TableRow
                                            key={item._id}
                                        >
                                            <TableCell className="fz-16">{item.email}</TableCell>
                                            <TableCell className="fz-16">
                                                <Moment format="DD/MM/YYYY">
                                                    {item.createdAt}
                                                </Moment>
                                            </TableCell>
                                            <TableCell className="fz-16">
                                                <Moment format="DD/MM/YYYY">
                                                    {item.updatedAt}
                                                </Moment>
                                            </TableCell>
                                            <TableCell className="fz-16">{item.isSubscription ? "Yes" : "No"}</TableCell>
                                        </TableRow>
                                    );
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={12} className="empty-text text-center">
                                        No data found
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Box>
                <Box
                    style={{
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "30px",
                    }}
                >
                    {usersList?.docs?.length > 0 ? (
                        <Stack spacing={2}>
                            <Pagination
                                count={usersList?.totalPages}
                                onChange={pagingHandler}
                            />
                        </Stack>
                    ) : (
                        ""
                    )}
                </Box>
            </TableContainer>
        );
    };

    return (
        <Box
            display="flex"
            className="box-wrapper br-24 table_bx"
            flexDirection="column"
            justifyContent="start"
        >
            {isloading ? <Loader /> : ""}
            <div className="heading-btn_wrapper">
                <h5
                    style={{ display: "inline-flex" }}
                    className="fz-24 fw-500 mb-0"
                >
                    Subscribers List
                </h5>
                <Search searchHandler={setSearchText} />
            </div>

            {tableData()}
        </Box>
    );
}

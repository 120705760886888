import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Router from './layout/Router';
import { AdminCtxProvider } from './context/admin-context';
const App = () => {
	return (

		<AdminCtxProvider>
				<ToastContainer
					position="top-right"
					autoClose={1200}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					theme="light"
					className='toast-message'
				/>
				<Router />
		</AdminCtxProvider>

	)
}

export default App

import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Button,
} from "@mui/material";
import { Loader } from "../../Components/Loader";
import FileIcon from "../../Components/assets/images/file-upload.png";
import AeroIcon from "../../Components/assets/images/airplane-icon.svg";
import HotelIcon from "../../Components/assets/images/hotel-icon.svg";
import HikingIcon from "../../Components/assets/images/hiking-icon.svg";
import DeleteModal from "../../Components/DeleteModal";
import { addData, post } from "../../services/Service";
import {
  Create_Gallery,
  Delete_Gallery,
  Gallery_All,
} from "../../services/Url";
import { ACTIVITY, HOTEL, ALL, FLIGHT } from "../../services/Constants";
import AdminContext from "../../context/admin-context";
import { toast } from "react-toastify";

const ImageBase = process.env.REACT_APP_IMAGE_BASE;

export function handleFileChange(event, setSelectedFile) {
  const selectedFile = event.target.files[0];
  toast.dismiss();
  if (!selectedFile) {
    toast.error("No file selected")
    return;
  }
  // allowed file types
  const allowedTypes = ['image/jpeg', 'image/png'];

  // min and max file size 
  const minSize = 10240; // 10KB 
  const maxSize = 2097152 ; // 2MB 

  // minimum image dimensions 
  const minWidth = 800;
  const minHeight = 600;

  // selected file type is allowed
  if (!allowedTypes.includes(selectedFile.type)) {
    toast.error('Invalid file type. Please select an image.');
    setSelectedFile(null);
    event.target.value = null;
    return;
  }

  // Check file size is within the specified range
  if (selectedFile.size < minSize || selectedFile.size > maxSize) {
    toast.error('File size is not within the allowed range (10KB - 2MB).');
    setSelectedFile(null);
    event.target.value = null;
    return;
  }
  const reader = new FileReader();
  reader.onload = (e) => {
    const img = new Image();
    img.src = e.target.result;
    img.onload = () => {
      // Check landscape orientation 
      if (img.width > img.height) {
        if (img.width >= minWidth && img.height >= minHeight) {
          setSelectedFile(event.target.files[0]);
        } else {
          toast.error(`Image dimensions must be at least ${minWidth}x${minHeight} pixels.`);
          setSelectedFile(null);
          event.target.value = null;
        }
      } else {
        // portrait orientation (vertical)
        toast.error('Image must be in landscape orientation.');
        setSelectedFile(null);
        event.target.value = null;
      }
    };
  };
  reader.readAsDataURL(selectedFile);
}

const GalleryImageList = (props) => {
  const [isloading, setIsLoading] = useState(false);
  return (
    <>
      {isloading ? <Loader /> : ""}
      {props.galData.length > 0 ? (
        <ImageList cols={3} gap={8}>
          {props.galData?.map((item) => (
            <ImageListItem key={item._id} sx={{ position: "relative" }}>
              <img
                src={ImageBase + "coverimage/" + item.cover_image}
                alt="img"
                loading="lazy"
              />
              <ImageListItemBar
                sx={{
                  background: "transparent",
                  width: "100%",
                  height: "100%",
                }}
                actionIcon={
                  <div className="gallery-actions">
                    <DeleteModal
                      id={item._id}
                      url={Delete_Gallery}
                      type="gallery image"
                      loadingHandler={setIsLoading}
                    />
                  </div>
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
      ) : (
        <div className="mt-5 text-center gal-data">
          <p>Add images to the gallery</p>
        </div>
      )}
    </>
  );
};

const Gallery = () => {
  const [galleryData, setGalleryData] = useState([]);
  const [isloading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(ALL);
  const [selectedFile, setSelectedFile] = useState(null);

  const ctx = useContext(AdminContext);

  useEffect(() => {
    setIsLoading(true);
    const formBody = JSON.stringify({
      categoryType: activeTab,
    });
    setGalleryData([]);
    setTimeout(() => {
      post(Gallery_All, formBody, setGalleryData, setIsLoading);
    }, 800);
    setSelectedFile();
  }, [activeTab]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (selectedFile?.type.startsWith('image/')) {
      const formData = new FormData();
      formData.append("cover_image", selectedFile);
      formData.append("category", activeTab);
      ctx.galToggleHandler();
      addData(Create_Gallery, formData, setIsLoading, "Gallery Added Successfully!");
      setTimeout(() => {
        setSelectedFile();
      }, 1000);
    }
    else {
      toast.dismiss();
      setSelectedFile(null);
      toast.info('Please select an image before proceeding');
    }
  };

  useEffect(() => {
    //to hit api whenever an item is deleted
    const formBody = JSON.stringify({
      categoryType: activeTab,
    });
    setTimeout(() => {
      post(Gallery_All, formBody, setGalleryData, setIsLoading);
    }, 1000);
  }, [ctx.isDeleted, ctx.isGalCreated]);

  return (
    <Box
      display="flex"
      className="box-wrapper br-24 "
      flexDirection="column"
      justifyContent="start"
    >
      {isloading ? <Loader /> : ""}
      <div className="heading-btn_wrapper">
        <h5
          style={{ display: "inline-flex" }}
          className="fz-24 fw-500"
        >
          Gallery
        </h5>
      </div>
      <div className="px-4">
        {activeTab !== ALL && (
          <div className="text-end">
            <form onSubmit={handleSubmit}>
              <div className="drag-file">
                <div className="drag-box">
                  {selectedFile ? (
                    <img
                      src={URL.createObjectURL(selectedFile)}
                      alt="img"
                      className="gal-img"
                    />
                  ) : (
                    <>
                      <img src={FileIcon} alt="img" />
                      <p>Drag file to upload</p>
                    </>
                  )}
                </div>
                <input type="file" onChange={(event) => { handleFileChange(event, setSelectedFile) }} accept="image/*" />
              </div>

              <Button type="submit" className="btn-design mt-4">
                Submit
              </Button>

            </form>
          </div>
        )}
        <div className="gallery-btn">
          <button
            className={activeTab === ALL ? "active" : ""}
            onClick={() => setActiveTab(ALL)}
          >
            All
          </button>
          <button
            className={activeTab === FLIGHT ? "active" : ""}
            onClick={() => setActiveTab(FLIGHT)}
          >
            <img src={AeroIcon} alt="icon" style={{ marginTop: "6px" }} />
            Flight
          </button>
          <button
            className={activeTab === HOTEL ? "active" : ""}
            onClick={() => setActiveTab(HOTEL)}
          >
            <img src={HotelIcon} alt="icon" />
            Hotels
          </button>
          <button
            className={activeTab === ACTIVITY ? "active" : ""}
            onClick={() => setActiveTab(ACTIVITY)}
          >
            <img src={HikingIcon} alt="icon" />
            Activities
          </button>
        </div>

        {galleryData ? (
          <GalleryImageList galData={galleryData} />
        ) : (
          <div className="mt-5 text-center gal-data">
            <p>No Images added to the gallery</p>
          </div>
        )}
      </div>
    </Box>
  );
};
export default Gallery;

import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  FormControl,
  Box,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button,
} from "@mui/material";
import { toast } from "react-toastify";
import { Reset_Password } from "../../services/Url";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import logo from "../../Components/assets/images/logo_color.png";
import LoginImg from "../../Components/assets/images/destination1.png";
import * as Yup from "yup";
import { useFormik } from "formik";

const BaseUrl = process.env.REACT_APP_BASE_URL;

function ResetPassword(props) {
  const navigate = useNavigate();
  const { tokenId } = useParams();
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConPass, setShowConPass] = useState(false);

  const handleClickShowNewPass = () => setShowNewPass(!showNewPass);
  const handleMouseDownNewPass = () => setShowNewPass(!showNewPass);

  const handleClickShowConPass = () => setShowConPass(!showConPass);
  const handleMouseDownConPass = () => setShowConPass(!showConPass);

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .required("Password is required")
      .oneOf([Yup.ref("newPassword")], "Passwords must match"),
  });

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // console.log(values);
      fetch(`${BaseUrl + Reset_Password}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          forgot_token: tokenId,
          password: values.newPassword,
        }),
      })
        .then((res) => {
          const data = res.json();
          if (res.status === 404) {
            throw new Error("Forget Token not valid");
          } else if (res.status === 200) {
            return data;
          } else {
            throw new Error("Something went wrong!");
          }
        })
        .then((data) => {
          toast.success(data.message);
          navigate("/login");
        })
        .catch((err) => {
          toast.error(err.message);
          navigate("/login");
        });
    },
  });

  return (
    <Box className="lgn_mdl_wpr">
      <div>
        <div className="lgn_left">
          <img src={LoginImg} alt="img" />
        </div>
        <div className="lgn_right">
          <form
            onSubmit={formik.handleSubmit}
            className="lgn_mdl"
            style={{
              margin: "0 auto",
              padding: "40px",
            }}
          >
            <Box>
              <div className="logo">
                <img src={logo} alt="popular" loading="lazy" />
              </div>
              <h4>Reset Password</h4>
              <Box
                display="flex"
                width="100%"
                flexDirection="column"
                gap={4}
                marginBottom={4}
              >
                <FormControl
                  variant="outlined"
                  className={
                    formik.touched.newPassword &&
                    formik.errors.newPassword &&
                    "pass-err"
                  }
                >
                  <InputLabel htmlFor="newPassword">New Password</InputLabel>
                  <OutlinedInput
                    name="newPassword"
                    id="newPassword"
                    type={showNewPass ? "text" : "password"}
                    value={formik.values.newPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.newPassword && formik.errors.newPassword
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowNewPass}
                          onMouseDown={handleMouseDownNewPass}
                          edge="end"
                        >
                          {showNewPass ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="New Password"
                  />
                  {formik.touched.newPassword && formik.errors.newPassword && (
                    <div className="error-text">
                      {formik.errors.newPassword}
                    </div>
                  )}
                </FormControl>
                <FormControl
                  variant="outlined"
                  className={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword &&
                    "pass-err"
                  }
                >
                  <InputLabel htmlFor="confirmPassword">
                    Confirm Password
                  </InputLabel>
                  <OutlinedInput
                    name="confirmPassword"
                    id="confirmPassword"
                    type={showConPass ? "text" : "password"}
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConPass}
                          onMouseDown={handleMouseDownConPass}
                          edge="end"
                        >
                          {showConPass ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Confirm Password"
                  />
                  {formik.touched.confirmPassword &&
                    formik.errors.confirmPassword && (
                      <div className="error-text">
                        {formik.errors.confirmPassword}
                      </div>
                    )}
                </FormControl>
              </Box>
              <div className="d-flex justify-content-center align-items-center">
                <Button type="submit" className="login-btn">
                  Submit
                </Button>
              </div>

            </Box>
          </form>
        </div>
      </div>
    </Box>
  );
}

export default ResetPassword;

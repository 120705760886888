import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  ACTIVITY,
  ACTIVITY_TICKET,
  FLIGHT,
  FLIGHT_TICKET,
  HOTEL,
  HOTEL_TICKET,
} from "../../services/Constants";
import { useParams } from "react-router-dom";
import { updateData } from "../../services/Service";
import { Add_Ticket, Is_Ticket_Added } from "../../services/Url";
import AdminContext from "../../context/admin-context";
import TicketModal from "../../Components/TicketModal";

const tomorrow = dayjs().add(1, "day");
const maxStartDate = dayjs().add(3, "month");

//validation for activity and hotel form
const validationSchema = Yup.object().shape({
  title: Yup.string()
    .required("Title is required")
    .min(5, "Title must be at least 5 characters")
    .max(1000, "Title can't exceed 1000 characters"),
  description: Yup.string()
    .required("Description is required")
    .min(5, "Description must be at least 5 characters")
    .max(1000, "Description can't exceed 1000 characters"),
  startDate: Yup.date()
    .min(new Date(), "Start date and time must be in the future")
    .required("Start date and time is required"),
  endDate: Yup.date()
    .min(Yup.ref("startDate"), "End date must be after start date")
    .max(
      new Date(new Date().setMonth(new Date().getMonth() + 3)),
      "End date can be up to 3 months from start date"
    )
    .required("End date is required"),
  ticket: Yup.mixed()
    .required("Ticket is required")
    .test("fileFormat", "Unsupported file format", (value) => {
      if (!value) return true;

      const acceptedFormats = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/pdf",
        "application/msword",
      ];
      return acceptedFormats.includes(value.type);
    }),
  totalAmount: Yup.number()
    .required("Total amount is required")
    .positive("Total amount must be a positive number")
    .min(0, "Total amount cannot be negative")
    .max(100000, "Total amount cannot be above 100000"),
});
const initialValues = {
  title: "",
  description: "",
  startDate: undefined,
  endDate: undefined,
  totalAmount: "",
  ticket: null,
};

const TicketForm = ({
  loading,
  source,
  destination,
  isTicAllHandler,
  totalAmount,
  discount,
}) => {
  const [activeTab, setActiveTab] = useState(FLIGHT);
  const { userId, tripId } = useParams();
  const ctx = useContext(AdminContext);
  const [isTicketAdded, setIsTicketAdded] = useState(false);

  let formType = () => {
    if (activeTab === FLIGHT) return FLIGHT_TICKET;
    else if (activeTab === HOTEL) return HOTEL_TICKET;
    else if (activeTab === ACTIVITY) return ACTIVITY_TICKET;
    else return;
  };

  const FlightCnt = () => {
    const [returnTicket, setReturnTicket] = useState(false);

    const validationSchema = Yup.object().shape({
      title: Yup.string()
        .required("Title is required")
        .min(5, "Title must be at least 5 characters")
        .max(1000, "Title can't exceed 1000 characters"),
      description: Yup.string()
        .required("Description is required")
        .min(5, "Description must be at least 5 characters")
        .max(1000, "Description can't exceed 1000 characters"),
      startDate: Yup.date()
        .min(new Date(), "Start date and time must be in the future")
        .required("Start date and time is required"),
      endDate: Yup.date()
        .min(Yup.ref("startDate"), "End date must be after start date")
        .max(
          new Date(new Date().setMonth(new Date().getMonth() + 3)),
          "End date can be up to 3 months from start date"
        )
        .required("End date is required"),
      ticket: Yup.mixed()
        .required("Ticket is required")
        .test("fileFormat", "Unsupported file format", (value) => {
          if (!value) return true; // Don't validate if no file is selected

          const acceptedFormats = [
            "image/jpeg",
            "image/jpg",
            "image/png",
            "application/pdf", // PDF format
            "application/msword", // Word document format
          ];

          return acceptedFormats.includes(value.type);
        }),
      source: Yup.string().required("Source is required"),
      destination: Yup.string()
        .required("Destination is required")
        .test("not-same", "Source and destination can't be the same", function (value) {
          return value !== this.parent.source;
        }),
      totalAmount: Yup.number()
        .required("Total amount is required")
        .positive("Total amount must be a positive number")
        .min(1, "Total amount cannot be negative")
        .max(100000, "Total amount cannot be above 100000"),
    });
    const formik = useFormik({
      initialValues: {
        title: "",
        description: "",
        startDate: undefined,
        endDate: undefined,
        totalAmount: "",
        source: source,
        destination: destination,
        ticket: null,
      },
      validationSchema: validationSchema,
      onSubmit: async (values) => {
        const formData = new FormData();
        formData.append("type", formType());
        formData.append("userId", userId);
        formData.append("title", values.title);
        formData.append("description", values.description);
        formData.append("start_date", values.startDate);
        formData.append("end_date", values.endDate);
        formData.append("source", values.source);
        formData.append("destination", values.destination);
        formData.append("Total_Amount", values.totalAmount);
        formData.append("upload_ticket", values.ticket);
        formData.append("return_ticket", returnTicket);
        //send data to API
        loading(true);
        const res = await updateData(
          Add_Ticket,
          tripId,
          formData,
          loading,
          "Flight Ticket Added"
        );
        if (res === 200) {
          ctx.flightTicHandler();
          setIsTicketAdded(true);
        }
      },
    });

    useEffect(() => {
      // Initialize Autocomplete for source and destination
      if (window.google && window.google.maps) {
        const sourceInput = document.getElementById('source');
        const destinationInput = document.getElementById('destination');

        const sourceAutocomplete = new window.google.maps.places.Autocomplete(sourceInput);
        const destinationAutocomplete = new window.google.maps.places.Autocomplete(destinationInput);

        sourceAutocomplete.addListener('place_changed', () => {
          const selectedPlace = sourceAutocomplete.getPlace();
          formik.setFieldValue('source', selectedPlace.formatted_address);
        });

        destinationAutocomplete.addListener('place_changed', () => {
          const selectedPlace = destinationAutocomplete.getPlace();
          formik.setFieldValue('destination', selectedPlace.formatted_address);
        });
      }
    }, [formik]);
    return (
      <form
        className="form_wrapper pwd_form px-4"
        onSubmit={formik.handleSubmit}
      >
        <div className="row">
          <div className="col-6">
            <Box className="inpt_bx mb-4">
              <Typography variant="h6">Flight Title</Typography>
              <TextField
                placeholder="Enter flight title"
                fullWidth
                name="title"
                id="title"
                type={"text"}
                variant="standard"
                {...formik.getFieldProps("title")}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              />
            </Box>
          </div>
          <div className="col-6 mb-3">
            <Box className="inpt_bx mb-4">
              <Typography variant="h6">Description</Typography>
              <TextField
                placeholder="Enter description"
                fullWidth
                name="description"
                type={"text"}
                id="description"
                variant="standard"
                {...formik.getFieldProps("description")}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />
            </Box>
          </div>
          <div className="col-6 mb-3">
            <Box
              className={`inpt_bx ${formik.touched.startDate && formik.errors.startDate
                ? "error-border"
                : ""
                }`}
            >
              <Typography variant="h6">Start Date and Time</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  minDate={tomorrow}
                  maxDate={maxStartDate}
                  value={formik.values.startDate}
                  onChange={(newValue) =>
                    formik.setFieldValue("startDate", newValue)
                  }
                  name="startDate"
                  onBlur={formik.handleBlur}
                  renderInput={(params) => <input {...params} />}
                  views={["year", "month", "day", "hours", "minutes"]}
                />
              </LocalizationProvider>
              {formik.touched.startDate && formik.errors.startDate ? (
                <div className="error-text">{formik.errors.startDate}</div>
              ) : null}
            </Box>
          </div>
          <div className="col-6">
            <Box
              className={`inpt_bx ${formik.touched.endDate && formik.errors.endDate
                ? "error-border"
                : ""
                }`}
            >
              <Typography variant="h6">End Date and Time</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  minDate={dayjs(formik.values.startDate)}
                  maxDate={dayjs(formik.values.startDate).add(3, "month")}
                  value={formik.values.endDate}
                  onChange={(newValue) =>
                    formik.setFieldValue("endDate", newValue)
                  }
                  name="endDate"
                  onBlur={formik.handleBlur}
                  renderInput={(params) => <input {...params} />}
                  views={["year", "month", "day", "hours", "minutes"]}
                />
              </LocalizationProvider>
              {formik.touched.endDate && formik.errors.endDate ? (
                <div className="error-text">{formik.errors.endDate}</div>
              ) : null}
            </Box>
          </div>
          <div className="col-6">
            <Box className="inpt_bx mb-4">
              <Typography variant="h6">Source</Typography>
              <TextField
                placeholder="Enter source"
                fullWidth
                name="source"
                id="source"
                variant="standard"
                value={formik.values.source}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.source && Boolean(formik.errors.source)}
                helperText={formik.touched.source && formik.errors.source}
              />
            </Box>
          </div>
          <div className="col-6">
            <Box className="inpt_bx mb-4">
              <Typography variant="h6">Destination</Typography>
              <TextField
                placeholder="Enter destination"
                fullWidth
                name="destination"
                id="destination"
                type={"text"}
                variant="standard"
                value={formik.values.destination}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  formik.touched.destination &&
                  Boolean(formik.errors.destination)
                }
                helperText={
                  formik.touched.destination && formik.errors.destination
                }
              />
            </Box>
          </div>
          <div className="col-6">
            <Box className="inpt_bx mb-4">
              <Typography variant="h6">Total Amount</Typography>
              <TextField
                placeholder="Enter total amount"
                fullWidth
                name="totalAmount"
                type={"number"}
                id="totalAmount"
                variant="standard"
                {...formik.getFieldProps("totalAmount")}
                error={
                  formik.touched.totalAmount &&
                  Boolean(formik.errors.totalAmount)
                }
                helperText={
                  formik.touched.totalAmount && formik.errors.totalAmount
                }
              />
            </Box>
          </div>
          <div className="col-6">
            <Box className="inpt_bx mb-4">
              <Typography variant="h6">Upload Ticket</Typography>
              <TextField
                placeholder="Upload ticket"
                fullWidth
                name="ticket"
                id="ticket"
                type={"file"}
                variant="standard"
                onChange={(event) =>
                  formik.setFieldValue("ticket", event.target.files[0])
                }
                error={formik.touched.ticket && Boolean(formik.errors.ticket)}
                helperText={formik.touched.ticket && formik.errors.ticket}
              />
            </Box>
          </div>
          <div className="col-6">
            <Box className="inpt_bx">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={returnTicket}
                    onChange={(event) => setReturnTicket(event.target.checked)}
                  />
                }
                label="Return Ticket"
              />
            </Box>
          </div>
          <div className="d-flex gap-3 justify-content-end mt-4">
            <Button className="btn btn-design" type="submit">
              Save
            </Button>
          </div>
        </div>
      </form>
    );
  };

  const ActivityCnt = () => {
    const formik = useFormik({
      initialValues: initialValues,
      validationSchema: validationSchema,
      onSubmit: async (values) => {
        const formData = new FormData();
        formData.append("type", formType());
        formData.append("userId", userId);
        formData.append("title", values.title);
        formData.append("description", values.description);
        formData.append("start_date", values.startDate);
        formData.append("end_date", values.endDate);
        formData.append("Total_Amount", values.totalAmount);
        formData.append("upload_ticket", values.ticket);
        //send data to API
        loading(true);
        const res = await updateData(
          Add_Ticket,
          tripId,
          formData,
          loading,
          "Activity Ticket Added"
        );
        if (res === 200) {
          ctx.activityTicHandler();
          setIsTicketAdded(true);
        }
      },
    });
    return (
      <form
        className="form_wrapper pwd_form px-4"
        onSubmit={formik.handleSubmit}
      >
        <div className="row">
          <div className="col-6">
            <Box className="inpt_bx mb-4">
              <Typography variant="h6">Activity Title</Typography>
              <TextField
                placeholder="Enter activity title"
                fullWidth
                name="title"
                id="title"
                type={"text"}
                variant="standard"
                {...formik.getFieldProps("title")}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              />
            </Box>
          </div>
          <div className="col-6">
            <Box className="inpt_bx mb-4">
              <Typography variant="h6">Description</Typography>
              <TextField
                placeholder="Enter description"
                fullWidth
                name="description"
                id="description"
                type={"text"}
                variant="standard"
                {...formik.getFieldProps("description")}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />
            </Box>
          </div>
          <div className="col-6 mb-3">
            <Box
              className={`inpt_bx ${formik.touched.startDate && formik.errors.startDate
                ? "error-border"
                : ""
                }`}
            >
              <Typography variant="h6">Start Date and Time</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  minDate={tomorrow}
                  maxDate={maxStartDate}
                  value={formik.values.startDate}
                  onChange={(newValue) =>
                    formik.setFieldValue("startDate", newValue)
                  }
                  name="startDate"
                  onBlur={formik.handleBlur}
                  renderInput={(params) => <input {...params} />}
                  views={["year", "month", "day", "hours", "minutes"]}
                />
              </LocalizationProvider>
              {formik.touched.startDate && formik.errors.startDate ? (
                <div className="error-text">{formik.errors.startDate}</div>
              ) : null}
            </Box>
          </div>
          <div className="col-6 mb-3">
            <Box
              className={`inpt_bx ${formik.touched.endDate && formik.errors.endDate
                ? "error-border"
                : ""
                }`}
            >
              <Typography variant="h6">End Date and Time</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  minDate={dayjs(formik.values.startDate)}
                  maxDate={dayjs(formik.values.startDate).add(3, "month")}
                  value={formik.values.endDate}
                  onChange={(newValue) =>
                    formik.setFieldValue("endDate", newValue)
                  }
                  name="endDate"
                  onBlur={formik.handleBlur}
                  renderInput={(params) => <input {...params} />}
                  views={["year", "month", "day", "hours", "minutes"]}
                />
              </LocalizationProvider>
              {formik.touched.endDate && formik.errors.endDate ? (
                <div className="error-text">{formik.errors.endDate}</div>
              ) : null}
            </Box>
          </div>
          <div className="col-6">
            <Box className="inpt_bx mb-4">
              <Typography variant="h6">Total Amount</Typography>
              <TextField
                placeholder="Enter total amount"
                fullWidth
                name="totalAmount"
                id="totalAmount"
                type={"number"}
                variant="standard"
                {...formik.getFieldProps("totalAmount")}
                error={
                  formik.touched.totalAmount &&
                  Boolean(formik.errors.totalAmount)
                }
                helperText={
                  formik.touched.totalAmount && formik.errors.totalAmount
                }
              />
            </Box>
          </div>
          <div className="col-6">
            <Box className="inpt_bx mb-4">
              <Typography variant="h6">Upload Ticket</Typography>
              <TextField
                placeholder="Upload ticket"
                fullWidth
                name="ticket"
                id="ticket"
                type={"file"}
                variant="standard"
                onChange={(event) =>
                  formik.setFieldValue("ticket", event.target.files[0])
                }
                error={formik.touched.ticket && Boolean(formik.errors.ticket)}
                helperText={formik.touched.ticket && formik.errors.ticket}
              />
            </Box>
          </div>
          <div className="d-flex gap-3 justify-content-end mt-4">
            <Button className="btn btn-design" type="submit">
              Save
            </Button>
          </div>
        </div>
      </form>
    );
  };

  const HotelCnt = () => {
    const formik = useFormik({
      initialValues: initialValues,
      validationSchema: validationSchema,
      onSubmit: async (values) => {
        const formData = new FormData();
        formData.append("type", formType());
        formData.append("userId", userId);
        formData.append("title", values.title);
        formData.append("description", values.description);
        formData.append("start_date", values.startDate);
        formData.append("end_date", values.endDate);
        formData.append("Total_Amount", values.totalAmount);
        formData.append("upload_ticket", values.ticket);
        //send data to API
        loading(true);
        const res = await updateData(
          Add_Ticket,
          tripId,
          formData,
          loading,
          "Hotel Ticket Added"
        );
        if (res === 200) {
          ctx.hotelTicHandler();
          setIsTicketAdded(true);
        }
      },
    });
    return (
      <form
        className="form_wrapper pwd_form px-4"
        onSubmit={formik.handleSubmit}
      >
        <div className="row">
          <div className="col-6">
            <Box className="inpt_bx mb-4">
              <Typography variant="h6">Hotel Title</Typography>
              <TextField
                placeholder="Enter hotel title"
                fullWidth
                name="title"
                id="title"
                type={"text"}
                variant="standard"
                {...formik.getFieldProps("title")}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              />
            </Box>
          </div>
          <div className="col-6">
            <Box className="inpt_bx mb-4">
              <Typography variant="h6">Description</Typography>
              <TextField
                placeholder="Enter description"
                fullWidth
                name="description"
                type={"text"}
                id="description"
                variant="standard"
                {...formik.getFieldProps("description")}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />
            </Box>
          </div>
          <div className="col-6 mb-3">
            <Box
              className={`inpt_bx ${formik.touched.startDate && formik.errors.startDate
                ? "error-border"
                : ""
                }`}
            >
              <Typography variant="h6">Start Date and Time</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  minDate={tomorrow}
                  maxDate={maxStartDate}
                  value={formik.values.startDate}
                  onChange={(newValue) =>
                    formik.setFieldValue("startDate", newValue)
                  }
                  name="startDate"
                  onBlur={formik.handleBlur}
                  renderInput={(params) => <input {...params} />}
                  views={["year", "month", "day", "hours", "minutes"]}
                />
              </LocalizationProvider>
              {formik.touched.startDate && formik.errors.startDate ? (
                <div className="error-text">{formik.errors.startDate}</div>
              ) : null}
            </Box>
          </div>
          <div className="col-6 mb-3">
            <Box
              className={`inpt_bx ${formik.touched.endDate && formik.errors.endDate
                ? "error-border"
                : ""
                }`}
            >
              <Typography variant="h6">End Date and Time</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  minDate={dayjs(formik.values.startDate)}
                  maxDate={dayjs(formik.values.startDate).add(3, "month")}
                  value={formik.values.endDate}
                  onChange={(newValue) =>
                    formik.setFieldValue("endDate", newValue)
                  }
                  name="endDate"
                  onBlur={formik.handleBlur}
                  renderInput={(params) => <input {...params} />}
                  views={["year", "month", "day", "hours", "minutes"]}
                />
              </LocalizationProvider>
              {formik.touched.endDate && formik.errors.endDate ? (
                <div className="error-text">{formik.errors.endDate}</div>
              ) : null}
            </Box>
          </div>
          <div className="col-6">
            <Box className="inpt_bx mb-4">
              <Typography variant="h6">Total Amount</Typography>
              <TextField
                placeholder="Enter total amount"
                fullWidth
                name="totalAmount"
                type={"number"}
                id="totalAmount"
                variant="standard"
                {...formik.getFieldProps("totalAmount")}
                error={
                  formik.touched.totalAmount &&
                  Boolean(formik.errors.totalAmount)
                }
                helperText={
                  formik.touched.totalAmount && formik.errors.totalAmount
                }
              />
            </Box>
          </div>
          <div className="col-6">
            <Box className="inpt_bx mb-4">
              <Typography variant="h6">Upload Ticket</Typography>
              <TextField
                placeholder="Upload ticket"
                fullWidth
                name="ticket"
                type={"file"}
                id="ticket"
                variant="standard"
                onChange={(event) =>
                  formik.setFieldValue("ticket", event.target.files[0])
                }
                error={formik.touched.ticket && Boolean(formik.errors.ticket)}
                helperText={formik.touched.ticket && formik.errors.ticket}
              />
            </Box>
          </div>
          <div className="d-flex gap-3 justify-content-end mt-4">
            <Button className="btn btn-design" type="submit">
              Save
            </Button>
          </div>
        </div>
      </form>
    );
  };

  return (
    <Box
      display="flex"
      className="trip_wrapper mt-5 mx-4"
      flexDirection="column"
      justifyContent="flex-start"
    >
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div className="tabs-btn">
          <button
            className={activeTab === FLIGHT ? "active" : ""}
            onClick={() => setActiveTab(FLIGHT)}
          >
            Add flight Tickets
          </button>
          <button
            className={activeTab === HOTEL ? "active" : ""}
            onClick={() => setActiveTab(HOTEL)}
          >
            Add hotel tickets
          </button>
          <button
            className={activeTab === ACTIVITY ? "active" : ""}
            onClick={() => setActiveTab(ACTIVITY)}
          >
            Add activities
          </button>
        </div>
      </div>
      {activeTab === FLIGHT && <FlightCnt />}
      {activeTab === HOTEL && <HotelCnt />}
      {activeTab === ACTIVITY && <ActivityCnt />}
      {totalAmount > 0 && (
        <div className="px-4 mt-4 info-box">
          <TicketModal
            id={tripId}
            url={Is_Ticket_Added}
            type="Ticket"
            isTicAllHandler={isTicAllHandler}
            totalAmount={totalAmount}
            discount={discount}
          />
        </div>
      )}
    </Box>
  );
};
export default TicketForm;

export const Signin = "admin-sign-in";
export const Admin_Dashboard = "admin/dashboard";
export const Forgot_Password = "forget-password-admin";
export const Change_Password = "admin/change-password";
export const Reset_Password = "reset-password-admin";

//offer management
export const Offer_Management = 'admin/get-offer';
export const Create_Offer = 'admin/offer';
export const Update_Offer = 'admin/update-offer';
export const Delete_Offer = 'admin/delete-offer';
export const Get_Offer_Detail = 'admin/get-individual-offer-detail';

//holiday package
export const Holiday_Package = 'admin/get-holiday';
export const Create_Holiday = 'admin/create-hoilday';
export const Update_Holiday = 'admin/update-holiday';
export const Delete_Holiday = 'admin/delete-holiday';
export const Get_Holiday_Detail = 'admin/get-individual-holiday-detail';

//User management
export const User_Management = 'admin/get-user-list';
export const Update_User = 'admin/edit-profile-by-admin';
export const Delete_User = 'admin/delete-profile-by-admin';
export const Enable_User = 'admin/user-enable';
export const Disable_User = 'admin/user-disable';
export const Get_User_Detail = 'admin/get-individual-user-detail';
export const Get_User_Trip_Detail = 'admin/get-user-trip-detail';

//Gallery management
export const Gallery_All = 'admin/get-gallery';
export const Gallery_Flight = 'admin/get-gallery/flight';
export const Gallery_Hotel = 'admin/get-gallery/hotel';
export const Gallery_Activity = 'admin/get-gallery/activity';
export const Create_Gallery = 'admin/create-gallery';
export const Delete_Gallery = 'admin/delete-gallery';

//Trip Management
export const Trip_Management_All = 'admin/get-all-trip-list';
export const Trip_Management_New = 'admin/get-new-trip-list';
export const Individual_Trip_Details = 'admin/get-trip-of-individual-user';
export const Add_Ticket = 'admin/add-ticket';
export const Remove_Ticket = 'admin/delete-ticket';
export const Get_Ticket = 'admin/get-ticket-by-admin';
export const Get_Ticket_Admin = 'admin/get-trip-with-ticket-by-admin';
export const Get_Rating = 'admin/get-rating-admin';
export const Is_Ticket_Added = 'admin/all-ticket-added';

//Bank Receipt
export const Get_Bank_Receipt = 'admin/banker-receipt';
export const Approve_Bank_Receipt = 'admin/approved-banker-receipt';
export const Reject_Bank_Receipt = 'admin/reject-banker-receipt';

// Stats User
export const User_Today = "admin/user-today";
export const User_Week = "admin/user-week";
export const User_month = "admin/user-month";
export const User_quater = "admin/user-quater";
export const User_Year = "admin/user-year";

//Settings
export const createCms = "admin/create-cms";
export const getCms = "get-cms";

// Subscriber
export const Subscriber_List = "admin/get-subscription-list-admin";
export const Get_Contact = 'get-contact-detail';
export const Edit_Contact = 'admin/contact-detail';


//Chat 
export const Get_Chat_List = 'admin/trip-holiday-list-chat';
export const Prev_Chats = 'admin/get-chat-admin';
export const Send_Chat_File = 'file-upload';


//Itinerary
export const Get_Itinerary_List = 'admin/get-list-itinerary';
export const Get_Itinerary_Detail = 'admin/get-itinerary-detail';
export const Delete_Itinerary = 'admin/delete-itinerary';

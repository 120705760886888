import React from "react";
import RationgEmojiList from "../../Components/Rating";

const Feedback = ({ ratingData }) => {
  return (
    <div className="feedback-card pe-none mt-4">
      <h4>Client Feedback</h4>
      {/*<p>What do Client think of Yone Travel & Tour?</p>*/}

      <RationgEmojiList rate={ratingData.rating_number} />
      <div className="cmt-bx">
        <label>Do you have any thoughts you’d like to share?</label>
        {
          <p
          >{ratingData.message}</p>
        }
      </div>
    </div>
  );
};
export default Feedback;

import React from "react";
import toggleIcon from "../Components/assets/images/toggle-icon.png";
import profileIcon from "../Components/assets/images/profile-img.png";

function Header(props) {

  return (
    <div className="header">
      <div className="header-left">
        <button onClick={()=>props.toggleHandler()}>
          <img src={toggleIcon} alt="toggle-icon" />
        </button>
      </div>
      {/*<div className="header-right">
        <img src={profileIcon} alt="user-profile" />
  </div> */}
    </div>
  );
}

export default Header;

import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { Loader } from "../../Components/Loader";
import { useNavigate, useParams } from "react-router-dom";
import editIcon from "../../Components/assets/images/edit-icon.svg";
import ProfilePic from "../../Components/assets/images/profilePicture.png";
import * as Yup from "yup";
import { useFormik } from "formik";
import { getData, updateData } from "../../services/Service";
import { Get_User_Detail, Update_User } from "../../services/Url";


const ImageBase = process.env.REACT_APP_IMAGE_BASE;
const UserEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [userData, setUserData] = useState();
  const [userImg, setUserImg] = useState(ProfilePic);
  const [isloading, setIsloading] = useState(false);



  useEffect(() => {
    getData(Get_User_Detail, id, setUserData, setIsloading);
  }, [id]);

  useEffect(() => {
    if (userData?.profile_picture) {
      setUserImg(ImageBase + "profile/" + userData?.profile_picture);
    }
  }, [userData]);

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Name is required")
      .matches(/^[a-zA-Z\s]+$/, "Name can only contain characters")
      .min(3, "Name must be atleasr 3 characters long"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone: Yup.string()
      .matches(/^\d{0,10}$/, 'Phone number can be up to 10 digits')
      .required("Phone number is required"),
    image: Yup.mixed()
      .test("fileSize", "Image size is too large(upto 1MB allowed)", (value) => {
        if (!value) return true; // No file provided, consider it valid
        return value && value.size <= 1048576;
      })
      .test("fileType", "Invalid file format", (value) => {
        if (!value) return true; // No file provided, consider it valid
        return (
          value && ["image/jpeg", "image/png", "image/jpg"].includes(value.type)
        );
      }),
  });

  const formik = useFormik({
    initialValues: {
      name: userData ? userData.name : "",
      email: userData ? userData.email : "",
      phone: userData ? userData.phone_number : "",
      image: null, //setting initial value for input field null
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("email", values.email);
      formData.append("phone_number", values.phone);
      formData.append("profile_picture", values.image);
      updateData(Update_User, id, formData, setIsloading, "User details updated");
      navigate("/usermanagement");
    },
    enableReinitialize: true, // Reinitialize when initialValues change
  });

  return (
    <Box
      display="flex"
      className="box-wrapper br-24 table_bx"
      flexDirection="column"
      justifyContent="start"
    >
      {isloading ? <Loader /> : ""}
      <div className="heading-btn_wrapper">
        <h5
          style={{ display: "inline-flex", cursor: "pointer" }}
          className="fz-24 fw-500"
        >
          Edit User
        </h5>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-6 mx-auto mb-4">
            <Box className="prfl_img">
              <img src={userImg} alt="user-img" className="pro-img" />
              <label htmlFor="icon-button-file">
                <TextField
                  id="icon-button-file"
                  type="file"
                  name="image"
                  sx={{ display: "none" }}
                  onChange={(e) => {
                    const selectedImage = e.currentTarget.files[0];
                    formik.setFieldValue("image", selectedImage);
                    setUserImg(URL.createObjectURL(selectedImage)); // Update coverImg state
                  }}
                />
                <div className="edit-icon">
                  <img src={editIcon} alt="edit icon" />
                </div>
              </label>
            </Box>
            {formik.touched.image && formik.errors.image && (
              <div className="error-text text-center mt-3">
                {formik.errors.image}
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-6 mx-auto">
            <Box className="inpt_bx">
              <Typography variant="h6">Name</Typography>
              <TextField
                fullWidth
                name="name"
                placeholder="Name"
                type="name"
                {...formik.getFieldProps("name")}
                error={formik.touched.name && formik.errors.name}
                helperText={formik.touched.name && formik.errors.name}
                className={
                  formik.touched.name && formik.errors.name
                    ? "error-border"
                    : ""
                }
              />
            </Box>
            <Box className="inpt_bx">
              <Typography variant="h6">Email</Typography>
              <TextField
                fullWidth
                name="email"
                placeholder="Email"
                type="email"
                {...formik.getFieldProps("email")}
                error={formik.touched.email && formik.errors.email}
                helperText={formik.touched.email && formik.errors.email}
                className={
                  formik.touched.email && formik.errors.email
                    ? "error-border"
                    : ""
                }
              />
            </Box>
            <Box className="inpt_bx mt-4">
              <Typography variant="h6">Phone Number</Typography>
              <TextField
                fullWidth
                name="phone"
                placeholder="Phone number"
                type="tel"
                {...formik.getFieldProps("phone")}
                error={formik.touched.phone && formik.errors.phone}
                helperText={formik.touched.phone && formik.errors.phone}
                className={
                  formik.touched.phone && formik.errors.phone
                    ? "error-border"
                    : ""
                }
              />
            </Box>
            <div className="inpt_bx text-center mt-4">
              <Button className="btn btn-design" type="submit">
                Save
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Box>
  );
};

export default UserEdit;

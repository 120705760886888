import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ChartDropdown = (props) => {
  const handleDropdownChange = (eventKey) => {
    // Perform additional actions based on the selected item
    props.yearHanlder(eventKey);
  };

  return (
    <Dropdown onSelect={handleDropdownChange}>
      <Dropdown.Toggle variant="light">
        <IconButton>
          <MoreHorizIcon />
        </IconButton>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item eventKey="2023">2023</Dropdown.Item>
        <Dropdown.Item eventKey="2022">2022</Dropdown.Item>
        <Dropdown.Item eventKey="2021">2021</Dropdown.Item>
        <Dropdown.Item eventKey="2020">2020</Dropdown.Item>
        <Dropdown.Item eventKey="2019">2019</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const overviewData = [
  {
    id: 1,
    year: '2019',
    value: 45,
  },
  {
    id: 2,
    year: '2020',
    value: 78,
  },
  {
    id: 3,
    year: '2021',
    value: 35,
  },
  {
    id: 4,
    year: '2022',
    value: 85,
  },
  {
    id: 5,
    year: '2023',
    value: 56,
  }
];

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function (tooltipItems) {
          let label = tooltipItems.dataset.label || '';
          if (label) {
            label += ': ';
          }
          if (tooltipItems.parsed !== null) {
            label += tooltipItems.formattedValue + '%';
          }
          return label;
        }
      }
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        color: "#00000066",
        font: {
          size: 14,
        },
      },
    },
    y: {
      grid: {
        display: true,
        drawBorder: true,
      },
      ticks: {
        color: "#00000066",
        font: {
          size: 14,
        },
        callback: function (value) {
          return value;
        },
      },
    },
  },
};

const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export function OverviewChart(props) {
  const [chartData, setChartData] = useState(overviewData);
  const date = new Date();
  let year = date.getFullYear();

  const [selectedYear, setSelectedYear] = useState(year);

  const yearHanlderFun = (year) => {
    setSelectedYear(parseInt(year));
  }

  const data = {
    labels: props.chartData?.map((data) => data.year),
    datasets: [
      {
        label: `${props.label}`,
        data: props.chartData?.map((data) => data.percentage),
        backgroundColor: `${props.color}`,
        borderWidth: 0,
        borderRadius: 5,
        barThickness: 35,
      },
    ],
  };

  return (
    <div className="db-card">
      <div className="chart-head">
        <h2>Percentage of {props.label} in the last 5 years</h2>
        {/*<ChartDropdown yearHanlder={yearHanlderFun} />*/}
      </div>
      <div className="mt-4">
        <Bar data={data} options={options} />
      </div>
    </div>
  );
}

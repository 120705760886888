import { createContext, useState } from "react";

const AdminContext = createContext({
  isDeleted: false,
  isGalCreated: false,
  deleteToggler: () => { },
  galToggleHandler: () => { },
  flightTicUpdated: false,
  flightTicHandler: () => { },
  hotelTicUpdated: false,
  hotelTicHandler: () => { },
  activityTicUpdated: false,
  activityTicHandler: () => { }
});

export const AdminCtxProvider = (props) => {
  const [isDeleted, setIsDeleted] = useState(false);
  const [isGalCreated, setGalCreated] = useState(false);
  const [flightTicketUpdated, setFlightTicketUpdated] = useState(false);
  const [hotelTicketUpdated, setHotelTicketUpdated] = useState(false);
  const [activityTicketUpdated, setActivityTicketUpdated] = useState(false);

  const deleteHandler = () => {
    setIsDeleted((prevIsDeleted) => !prevIsDeleted);
  };
  const galHandler = () => {
    setGalCreated((prevIsDeleted) => !prevIsDeleted);
  };
  const flightTicketHandler = () => {
    setFlightTicketUpdated((prev) => !prev);
  }
  const hotelTicketHandler = () => {
    setHotelTicketUpdated((prev) => !prev);
  }
  const activityTicketHandler = () => {
    setActivityTicketUpdated((prev) => !prev);
  }

  return (
    <AdminContext.Provider
      value={{
        isDeleted: isDeleted,
        deleteToggler: deleteHandler,
        isGalCreated: isGalCreated,
        galToggleHandler: galHandler,
        flightTicUpdated: flightTicketUpdated,
        flightTicHandler: flightTicketHandler,
        hotelTicUpdated: hotelTicketUpdated,
        hotelTicHandler: hotelTicketHandler,
        activityTicUpdated: activityTicketUpdated,
        activityTicHandler: activityTicketHandler
      }}
    >
      {props.children}
    </AdminContext.Provider>
  );
};

export default AdminContext;

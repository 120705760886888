import React from "react";
import { alpha, styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { DISABLE, ENABLE } from "../services/Constants";

//Switch button
const SwitchBtn = styled(Switch)(() => ({
    //custom mui switch with different color
    "& .MuiSwitch-switchBase.Mui-checked": {
        color: "#004F87",
        "&:hover": {
            backgroundColor: alpha("#004F87"),
        },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#004F87",
    },
}));

const CustomSwitch = (props) => {
    const [isChecked, setChecked] = React.useState(props.status);

    const handleChange = () => {
        if (isChecked) {
            // disable the user
            props.accessHandler(DISABLE, props.id);
        }
        else {
            //enable the user
            props.accessHandler(ENABLE, props.id);
        }
        setChecked(!isChecked);
    };
    return (
        <>
            <SwitchBtn
                color="secondary"
                checked={isChecked}
                onChange={handleChange}
            />
        </>
    );
};
export default CustomSwitch;

import React, { useEffect, useState, useRef } from "react";
import chatlogo from "../../Components/assets/images/chatlogo.png";
import sendIcon from "../../Components/assets/images/sendIcon.png";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AttachmentIcon from "@mui/icons-material/Attachment";
import io from "socket.io-client";
import { Get_Chat_List, Send_Chat_File } from "../../services/Url";
import ChatList from "./ChatList";
import { post } from "../../services/Service";
import { ExpiredTrip, SentMessages } from "./MessageBox";
import MessageBox from "./MessageBox";
import DocPreview from "../../Components/assets/images/doc-file.png";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ServerUrl = process.env.REACT_APP_SERVER_URL;

export const Chat = () => {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [socket, setSocket] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [file, setFile] = useState(null);
  const [isExpired, setIsExpired] = useState(false);
  const [searchText, setSearchText] = useState("");
  const chatReference = useRef(null);
  const [prevChatLoaded, setprevChatLoaded] = useState(false);

  const [firstLoad, setFirstLoad] = useState(true);
  const [activeTripChat, setActiveTripChat] = useState({
    chatId: localStorage.getItem('activetripid'),
    type: localStorage.getItem('type'),
    userId: localStorage.getItem('userid'),
    roomId: localStorage.getItem('roomid'),
  });
  const location = useLocation();
  const navigate = useNavigate();
  const tripData = location.state?.chatData;

  useEffect(() => {
    //to get trip list for chat
    const body = JSON.stringify({
      search_string: searchText,
    });
    post(Get_Chat_List, body, setData, setIsLoading);
  }, [messages, activeTripChat, prevChatLoaded]);

  useEffect(() => {
    //searching
    if (!/^[a-zA-Z0-9]/.test(searchText[0])) {
      //first character shouldn't be special character
      return;
    }
    const delay = 500;
    let timerId;
    let body = JSON.stringify({
      search_string: searchText,
    });
    const delayedSearch = () => {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        // Make API call here
        post(Get_Chat_List, body, setData, setIsLoading);
      }, delay);
    };

    delayedSearch();
    return () => {
      clearTimeout(timerId);
    };
  }, [searchText]);

  useEffect(() => {
    //if navigated via trip management chat option then set the trip as the default active trip
    if (data) {
      if (tripData && firstLoad) {
        setActiveTripChat({
          chatId: tripData.chatId,
          type: tripData.type,
          userId: tripData.userId,
          roomId: tripData.chatId,
        });
        localStorage.setItem('activetripid', tripData.chatId);
        localStorage.setItem('type', tripData.type);
        localStorage.setItem('userid', tripData.userId);
        localStorage.setItem('roomid', tripData.chatId);
        navigate(location.pathname, { state: null });
      }
    }
  }, [tripData, data]);

  useEffect(() => {
    // Function to scroll to the bottom of the chat
    const scrollToBottom = () => {
      if (chatReference.current) {
        chatReference.current.scrollTop = chatReference.current.scrollHeight;
      }
    };
    const observer = new MutationObserver(scrollToBottom);

    if (chatReference.current) {
      observer.observe(chatReference.current, { childList: true });
    }
    return () => {
      observer.disconnect();
    };
  }, [messages, file]);

  useEffect(() => {
    //setting up socket connection
    const socketInstance = io(ServerUrl);
    socketInstance.on("connection", () => {
      // console.log(socketInstance.connected);
    });
    socketInstance.on("message", (msg) => {
      setMessages([...messages, msg]);
    });
    setSocket(socketInstance);
    // Clean up the socket connection when component unmounts
    return () => {
      if (socketInstance) {
        socketInstance.disconnect();
      }
    };
  }, [activeTripChat, messages, file]);

  const sendMessage = () => {
    if (socket && message.trim()) {
      // Emit the message to the server
      let chatData;
      if (activeTripChat.type === "trip") {
        chatData = {
          trip_id: activeTripChat.chatId,
          message: message,
          sentby_id: activeTripChat.userId,
          senderRole: "admin",
          type: "text",
        };
      } else {
        chatData = {
          holiday_id: activeTripChat.chatId,
          message: message,
          sentby_id: activeTripChat.userId,
          senderRole: "admin",
          type: "text"
        };
      }
      socket.emit("message", chatData);
      setMessage("");
    } else if (file) {
      const formData = new FormData();
      let chatData;
      formData.append("file", file);
      const url = ServerUrl + Send_Chat_File;
      fetch(url, {
        method: "POST",
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
        body: formData,
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error("Network response was not ok");
          }
          return res.json();
        })
        .then((data) => {
          if (activeTripChat.type === "trip") {
            chatData = {
              trip_id: activeTripChat.chatId,
              sentby_id: activeTripChat.userId,
              senderRole: "admin",
              message: data.uploadedFile,
              type: file.type,
            };
          } else {
            chatData = {
              holiday_id: activeTripChat.chatId,
              sentby_id: activeTripChat.userId,
              senderRole: "admin",
              message: data.uploadedFile,
              type: file.type,
            };
          }
          socket.emit("start", chatData);
          setFile(null);
        })
        .catch((error) => {
          console.error("Failed to upload file:", error);
          setFile(null);
        });
    }
  };


  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  const FileHandler = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const allowedTypes = [
        "image/jpeg",
        "image/png",
        "application/pdf",
        "application/msword",
      ];
      const maxSize = 10 * 1024 * 1024; // 10MB

      if (
        allowedTypes.includes(selectedFile.type) &&
        selectedFile.size <= maxSize
      ) {
        // File is valid, set it in state
        setFile(selectedFile);
      } else {
        // File is invalid, show an error
        toast.error(
          "File must be an image (jpg, png) or a document (pdf, docx) and not exceed 10MB in size."
        );
        setFile(null);
      }
    }
  };

  const undoFileHandler = () => {
    setFile(null);
  };

  useEffect(() => {
    undoFileHandler();
  }, [activeTripChat]);

  useEffect(() => {
    //to set the message in input field if last message was not sent
    const keysInLocalStorage = Object.keys(localStorage);
    let isChatIdInLocalStorage;
    if (activeTripChat?.type === "trip") {
      isChatIdInLocalStorage = keysInLocalStorage.includes(activeTripChat?.chatId);
      if (isChatIdInLocalStorage) {
        setMessage(localStorage.getItem(activeTripChat?.chatId));
        localStorage.removeItem(activeTripChat?.chatId);
      }
    } else if (activeTripChat?.type === "holiday") {
      isChatIdInLocalStorage = keysInLocalStorage.includes(activeTripChat?.roomId);
      if (isChatIdInLocalStorage) {
        setMessage(localStorage.getItem(activeTripChat?.roomId));
        localStorage.removeItem(activeTripChat?.roomId);
      }
    }
  }, [activeTripChat]);

  return (
    <div className="chat-page">
      {/* Chat*/}
      <div className="chat-ctnt">
        <h4 className="sec-inr-heading mb-4">Connect With Us</h4>
        <div className="chat-bx-otr">
          <div className="chat-bx-user">
            <div className="sec-inr-heading">
              <h4>Chat</h4>
            </div>
            <div className="chat-srch">
              <input
                type="text"
                placeholder="Search"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              // disabled={(data?.length === 0) ? true : false}
              />
              <SearchOutlinedIcon />
            </div>
            <ChatList
              item={data ? data : null}
              activeTrip={activeTripChat}
              activeTripHandler={setActiveTripChat}
              setFirstLoad={setFirstLoad}
              setIsExpired={setIsExpired}
              prevMsg={message}
              setPrevMsg={setMessage}
            />
          </div>
          <div className="chat-bx">
            <div className="chat-hdr">
              <div className="chat-img">
                <img src={chatlogo} className="img-fluid" alt="img" />
              </div>
              <div className="chat-info">
                <h5>Yone Travel & Tour</h5>
                <h6>We are here to help</h6>
              </div>
            </div>
            <div className="chat-msg-wpr" ref={chatReference}>
              {data?.length > 0 && (
                <>
                  <SentMessages />
                  <MessageBox activeChat={activeTripChat} messages={messages} file={file} setprevChatLoaded={setprevChatLoaded} />
                </>
              )}
              {isExpired && <ExpiredTrip />}
            </div>
            <div className="chat-ftr">
              {file && (
                <div className={file ? "file-preview show" : "file-preview"}>
                  {file?.type === "image/png" ||
                    file?.type === "image/jpeg" ||
                    file?.type === "image/jpg" ? (
                    <div className="img-preview">
                      <button onClick={undoFileHandler}>x</button>
                      <div>
                        <img alt="img" src={URL.createObjectURL(file)} />
                      </div>
                    </div>
                  ) : (
                    <div className="doc-preview">
                      <button onClick={undoFileHandler}>x</button>
                      <div>
                        <img alt="img" src={DocPreview} />
                        <p>{file.name}</p>
                        <p>{(file.size / 1024).toFixed(2)} KB</p>
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="input-box">
                <input
                  type="text"
                  placeholder="Type your Message..."
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyDown={handleKeyDown}
                  disabled={(data?.length === 0 || isExpired) ? true : false}
                  style={(data?.length === 0 || isExpired) ? { cursor: 'not-allowed' } : { cursor: 'pointer' }}
                />
                <div className="d-flex gap-3 align-items-center">
                  <div className="send-files">
                    <input
                      type="file"
                      onChange={FileHandler}
                      accept="image/jpeg, image/png, application/pdf, application/msword"
                    />
                    <AttachmentIcon />
                  </div>
                  <span>
                    <button onClick={sendMessage}>
                      <img src={sendIcon} alt="img" />
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

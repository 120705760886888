import React, { useState, useEffect } from "react";
import { Loader } from "../../Components/Loader";
import RegIcon from "../../Components/assets/images/registered.png";
import CaleToday from "../../Components/assets/images/calendar-week.png";
import CaleMonth from "../../Components/assets/images/calendar-month.png";
import CaleYear from "../../Components/assets/images/calendar-year.png";
import UserList from "./UserList";
import TripList from "./TripList";
import OfferList from "./OfferList";
import HolidayList from "./HolidayList";
import { OverviewChart } from "./OverviewChart";
import { PieChart } from "./PieChart";
import { get } from "../../services/Service";
import { Admin_Dashboard } from "../../services/Url";

const ManagementBox = (props) => {
  return (
    <div className="db-card">
      <h3>{props.title}</h3>
      <div className="management-cards">
        {props.data?.map((item, index) => {
          return (
            <div className="man-card" key={index}>
              <div>
                <div className="man-img">
                  <img alt="img" src={item.img} />
                </div>
                <div className="d-flex flex-column">
                  <p className="flex-grow-1">{item.label}</p>
                  <h2>{item.value}</h2>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const Dashboard = () => {
  const [dashData, setDashData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      get(Admin_Dashboard, setDashData, setIsLoading);
    }, 1500);
  }, []);

  const userData = [
    {
      label: "Total Users Registered",
      value: dashData?.user_total,
      img: `${RegIcon}`,
    },
    {
      label: "Total Users Registered Today",
      value: dashData?.user_today,
      img: `${CaleToday}`,
    },
    {
      label: "Total Users Registered in this Month",
      value: dashData?.user_month,
      img: `${CaleMonth}`,
    },
    {
      label: "Total Users Registered in this Year",
      value: dashData?.user_year,
      img: `${CaleYear}`,
    },
  ];
  const tripData = [
    {
      label: "Total Trips Requested",
      value: dashData?.trip_total,
      img: `${RegIcon}`,
    },
    {
      label: "Total Trips Requested  Today",
      value: dashData?.trip_today,
      img: `${CaleToday}`,
    },
    {
      label: "Total Trips Requested in this Month",
      value: dashData?.trip_month,
      img: `${CaleMonth}`,
    },
    {
      label: "Total Trips Requested in this Year",
      value: dashData?.trip_year,
      img: `${CaleYear}`,
    },
  ];
  const offerData = [
    {
      label: "Total Offers Created",
      value: dashData?.offers_total,
      img: `${RegIcon}`,
    },
    {
      label: "Total Offers Created Today",
      value: dashData?.offers_today,
      img: `${CaleToday}`,
    },
    {
      label: "Total Offers Created in this Month",
      value: dashData?.offers_month,
      img: `${CaleMonth}`,
    },
    {
      label: "Total Offers Created in this Year",
      value: dashData?.offers_year,
      img: `${CaleYear}`,
    },
  ];
  const holidayData = [
    {
      label: "Total Holiday Packages Created",
      value: dashData?.hoildays_total,
      img: `${RegIcon}`,
    },
    {
      label: "Total Holiday Packages Created Today",
      value: dashData?.hoildays_today,
      img: `${CaleToday}`,
    },
    {
      label: "Total Holiday Packages Created in this Month",
      value: dashData?.hoildays_month,
      img: `${CaleMonth}`,
    },
    {
      label: "Total Holiday Packages Created in this Year",
      value: dashData?.hoildays_year,
      img: `${CaleYear}`,
    },
  ];

  return (
    <div className="db-box">
      {isLoading ? <Loader /> : ""}
      <div className="row">
        <div className="col-12 my-3">
          <ManagementBox title="User Management" data={userData} />
        </div>
        <div className="col-8 my-3">
          <UserList list={dashData?.user} />
        </div>
        <div className="col-4 my-3">
          <PieChart chartData={dashData?.user_pie_chart} />
        </div>
        <div className="col-12 my-3">
          <ManagementBox title="Trip Management" data={tripData} />
        </div>
        <div className="col-6 my-3">
          <OverviewChart
            label="Trips Requested"
            color="#40C9A2"
            chartData={dashData?.trip_graph}
          />
        </div>
        <div className="col-6 my-3">
          <TripList list={dashData?.trip} />
        </div>
        <div className="col-12 my-3">
          <ManagementBox title="Offers Management" data={offerData} />
        </div>
        <div className="col-6 my-3">
          <OverviewChart
            label="Offers Created"
            color="rgb(252 210 40 / 80%)"
            chartData={dashData?.offer_graph}
          />
        </div>
        <div className="col-6 my-3">
          <OfferList list={dashData?.offers} />
        </div>
        <div className="col-12 my-3">
          <ManagementBox
            title="Holiday Packages Management"
            data={holidayData}
          />
        </div>
        <div className="col-6 my-3">
          <OverviewChart
            label="Holiday Packages Created"
            color="#65AFFF"
            chartData={dashData?.holiday_graph}
          />
        </div>
        <div className="col-6 my-3">
          <HolidayList list={dashData?.hoildays} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

import { useState, useEffect } from "react";
import JoditEditor from "jodit-react";
import { toast } from "react-toastify";
import { useRef } from "react";
import { createCms, getCms } from "../../services/Url";
import { Link } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { Visibility } from "@mui/icons-material";
import { TERMS } from "../../services/Constants";


const BaseUrl = process.env.REACT_APP_BASE_URL;
const Terms = () => {
  const editor = useRef(null);
  const [info, setInfo] = useState("");
  const [post, setPost] = useState();
  const [id, setId] = useState();

  useEffect(() => {
    getCMSAbout();
  }, [post]);

  const createData = async () => {
    if (id) {
      let data = {
        id,
        content: post,
      };
      let token = localStorage.getItem("token");
      let CompleteUrl = BaseUrl + createCms;

      await fetch(CompleteUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (res?.status === 200) {
            toast?.success("Update Successfully");
            getCMSAbout();
          }

        })
        .catch((err) => {
          console.log(err);
        });
    } else {
    }
  };

  const getCMSAbout = async () => {
    let data = {
      type: TERMS,
    };
    let CompleteUrl = BaseUrl + getCms;

    let response = await fetch(CompleteUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const jsonData = await response.json();
    if (jsonData.data != null) {
      setInfo(jsonData.data.content);
      setId(jsonData.data._id);
    } else {
      toast.error(jsonData.message);
    }
  };

  return (
    <Box
      display="flex"
      className="box-wrapper br-24 table_bx policy-box"
      flexDirection="column"
      justifyContent="start"
    >
      <div className="mx-auto mb-5">
        <h5 className="fz-24 fw-500">
          Terms & Conditions
          <span
            className="ms-2"
            style={{ position: "absolute", top: "10px", right: "30px" }}
          >
            <Link to='/viewterms' state={{ payload: info }}>
              <Visibility />
            </Link>
          </span>
        </h5>
      </div>
      <form className="pb-5 mt-2">
        <div className="my-3 privacy-policy">
          <JoditEditor
            ref={editor}
            value={info}
            onChange={(newContent) => setPost(newContent)}
          />
        </div>
        <div className="justify-content-center mt-5 d-flex actions">
          <Button onClick={createData}>Save</Button>
        </div>
      </form>
    </Box>
  );
};

export default Terms;

import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { Loader } from "../../Components/Loader";
import { getData } from "../../services/Service";
import { Get_Itinerary_Detail } from "../../services/Url";

const ItineraryView = () => {
    const [isloading, setIsloading] = useState(true);
    const [data, setData] = useState();
    const [photoUrl, setPhotoUrl] = useState("");
    const { id } = useParams();
    useEffect(() => {
        getData(Get_Itinerary_Detail, id, setData, setIsloading);
    }, []);


    useEffect(() => {
        // Define a function to fetch photos based on the user's input
        const fetchPhotos = () => {
            if (data?.destination && window.google && window.google.maps) {
                const placesService = new window.google.maps.places.PlacesService(
                    document.createElement("div")
                );

                const request = {
                    query: data?.destination,
                    fields: ["photos"],
                };

                placesService.findPlaceFromQuery(request, (results, status) => {
                    if (
                        status === window.google.maps.places.PlacesServiceStatus.OK &&
                        results.length > 0
                    ) {
                        const place = results[0];
                        const photos = place.photos;
                        console.log("place==>",place)
                        if (photos && photos.length > 0) {
                            // Set the URL of the first photo (small version)
                            const photoReference = photos[0].getUrl({
                                maxWidth: 700,
                                maxHeight: 700,
                            });
                            setPhotoUrl(photoReference);
                        } else {
                            setPhotoUrl("");
                        }
                    } else {
                        console.error("Error fetching place photos:", status);
                        setPhotoUrl("");
                    }
                });
            }
        };

        // Call the function to fetch photos when placeQuery changes
        fetchPhotos();
    }, [data?.destination]);

console.log("data===>",data)
    return (
        <Box
            display="flex"
            className="box-wrapper br-24 table_bx"
            flexDirection="column"
            justifyContent="start"
        >
            {isloading ? <Loader /> : ""}
            <div className="heading-btn_wrapper">
                <h5
                    style={{ display: "inline-flex", cursor: "pointer" }}
                    className="fz-24 fw-500"
                >
                    Trip Itinerary Management
                </h5>
            </div>
            <div className="trip-box">
                <div className="itinerary-data">
                    <div className="trip-form mb-5">
                        <h2>
                            {data?.days} Days Trip to {data?.destination}
                        </h2>
                        <h4>Budget: ${data?.budget}</h4>
                        <h4>Accommodation Prefrence: {data?.accommodation} ⭐ Hotel</h4>
                    </div>
                    <div className="itinerary-details">
                        <div className="row">
                            <div className="col-md-6 pe-5">
                                <div>
                                    <div className="google-photo">
                                        {photoUrl && <img src={photoUrl} alt="Place" />}
                                    </div>
                                    <p className="mt-4">{data?.destinationDetail}</p>
                                </div>
                            </div>
                            <div className="col-md-6 ps-md-5">
                                <div dangerouslySetInnerHTML={{ __html: data?.aiText }}></div>
                            </div>
                        </div>
                        {data?.query &&
                            <p className="custom-textarea">{data?.query}</p>
                        }
                    </div>
                </div>
            </div>
        </Box>
    );
};

export default ItineraryView;

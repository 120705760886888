import { toast } from "react-toastify";
import { Approve_Bank_Receipt, Create_Gallery, Create_Holiday, Create_Offer, Edit_Contact, Reject_Bank_Receipt } from "./Url";
const BaseUrl = process.env.REACT_APP_BASE_URL;

const tokenSetter = () => {
  let token = localStorage.getItem("token");

  let headers;
  if (token === "" || token == null || token === undefined) {
    headers = {
      "Content-Type": "application/json",
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      "x-access-token": token,
    };
  }
  return headers;
};

//Get request
export const get = async (url, setData, setIsloading) => {
  setIsloading(true);
  let headers = tokenSetter();
  const completeUrl = BaseUrl + url;

  try {
    const res = await fetch(completeUrl, {
      method: "GET",
      headers,
    });
    const response = await res.json();
    if (res.status === 200) {
      setIsloading(false);
      setData(response.data);
      return response.data;
    } else if (res.status === 401) {
      window.location.href = "/login";
      localStorage.removeItem("token");
      setIsloading(false);
    } else {
      setIsloading(false);
    }
  } catch (error) {
    return error;
  }
};

//Get request of particular item
export const getData = async (url, id, setData, setIsloading) => {
  setIsloading(true);
  let headers = tokenSetter();
  const completeUrl = BaseUrl + url + "/" + id;
  try {
    const res = await fetch(completeUrl, {
      method: "GET",
      headers,
    });
    const response = await res.json();
    if (res.status === 200) {
      setIsloading(false);
      if (url === Approve_Bank_Receipt) {
        return setData('Approved');
      }
      if (url === Reject_Bank_Receipt) {
        return setData('Rejected');
      }
      setData(response.data);
      return true;
    } else if (res.status === 401) {
      // toast.error(response.message);
      window.location.href = "/login";
      localStorage.removeItem("token");
      setIsloading(false);
    } else {
      toast.error(response.message);
      setIsloading(false);
    }
  } catch (error) {
    toast.error(error);
    return error;
  }
};

// Post request(getting data)
export const post = async (url, body, setData, setIsLoading) => {
  setIsLoading(true);
  let headers = tokenSetter();
  const completeUrl = BaseUrl + url;
  try {
    const res = await fetch(completeUrl, {
      method: "POST",
      headers,
      body,
    });

    const response = await res.json();
    let httpsStatus = res.status;

    if (httpsStatus === 200) {
      setData(response?.data);
      setIsLoading(false);
    } else if (httpsStatus === 401) {
      toast.error("Please login again");
      window.location.href = "/login";
      localStorage.removeItem("token");
      setIsLoading(false);
    } else {
      toast.error(response?.message);
      setIsLoading(false);
    }
  } catch (error) {
    toast.error(error);
    return error;
  }
};

// Update data request(sending form data)
export const updateData = async (url, id, body, setIsLoading, type) => {
  setIsLoading(true);
  let token = localStorage.getItem("token");

  const completeUrl = BaseUrl + url + "/" + id;

  try {
    const res = await fetch(completeUrl, {
      method: "POST",
      headers: {
        "x-access-token": token,
      },
      body,
    });

    const response = await res.json();
    let httpsStatus = res.status;

    if (httpsStatus === 200) {
      setTimeout(() => {
        setIsLoading(false);
        toast.success(type + " successfully");
      }, 1000);
      return httpsStatus;
    } else if (httpsStatus === 401) {
      window.location.href = "/login";
      localStorage.removeItem("token");
      setIsLoading(false);
    } else {
      toast.error(response?.message);
      setIsLoading(false);
    }
  } catch (error) {
    toast.error(error);
    return error;
  }
};

//Create API
export const addData = async (url, body, setIsLoading, type) => {
  toast.dismiss();
  let token = localStorage.getItem("token");
  setIsLoading(true);
  const completeUrl = BaseUrl + url;
  const headers = () => {
    if (url === Edit_Contact) {
      return {
        "x-access-token": token,
        "Content-Type": "application/json",
      }
    } else {
      return { "x-access-token": token }
    }
  }
  try {
    const res = await fetch(completeUrl, {
      method: "POST",
      headers: headers(),
      body,
    });

    let httpsStatus = res.status;
    let response = await res.json();


    if (httpsStatus === 200) {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
      toast.success(type);
    } else if (httpsStatus === 401) {
      if (url === Create_Offer) {
        toast.error("Offer Addition failed!");
      } else if (url === Edit_Contact) {
        toast.error("Contact Addition failed!");
      } else if (url === Create_Holiday) {
        toast.error("Holiday Addition failed!");
      } else if (url === Create_Gallery) {
        toast.error("Gallery Addition failed!");
      }
      setIsLoading(false);
    } else {
      toast.error(response?.message);
      setIsLoading(false);
    }
  } catch (error) {
    toast.error(type + " addition failed!");
    setIsLoading(false);
    return error;
  }
};

/* User enable and  disable API*/
export const userAccess = async (url, catId, msgType) => {
  let headers = tokenSetter();
  const completeUrl = BaseUrl + url + "/" + catId;

  try {
    const res = await fetch(completeUrl, {
      method: "POST",
      headers,
    });
    const response = await res.json();
    let httpsStatus = res.status;
    if (httpsStatus === 200) {
      toast.success("User " + msgType + "!");
    } else if (httpsStatus === 401) {
      toast.error(response?.message);
    } else {
      toast.error(response?.message);
    }
  } catch (error) {
    toast.error(error);
    return error;
  }
};

// Search API
export const searchData = async (url, body, setData) => {
  let headers = tokenSetter();
  const completeUrl = BaseUrl + url;

  try {
    const res = await fetch(completeUrl, {
      method: "POST",
      headers,
      body,
    });
    const response = await res.json();
    let httpsStatus = res.status;
    if (httpsStatus === 200) {
      setData(response?.data);
    } else if (httpsStatus === 401) {
      toast.error("Not found");
    } else {
      toast.error(response?.message);
    }
  } catch (error) {
    toast.error("Not found");
    return error;
  }
};

//Delete API
export const deleteData = async (url, id, setIsLoading, type) => {
  setIsLoading(true);
  let headers = tokenSetter();
  const completeUrl = BaseUrl + url + "/" + id;
  try {
    const res = await fetch(completeUrl, {
      method: "POST",
      headers,
    });
    const response = await res.json();
    const httpStatus = res.status;
    if (httpStatus === 200) {
      setTimeout(() => {
        toast.success(type + " deleted successfully");
        setIsLoading(false);
      }, 1000);

      return httpStatus;
    } else if (httpStatus === 401) {
      toast.error(type + " deletion failed!");
      setIsLoading(false);
    } else {
      toast.error(response?.message);
      setIsLoading(false);
    }
  } catch (error) {
    toast.error(type + " deletion failed!");
    return error;
  }
};

import { Link, useLocation } from "react-router-dom";
import { Box } from "@mui/material";

const TermView = () => {
    const location = useLocation();
    const { payload } = location.state || {};
    return (
        <Box
            display="flex"
            className="box-wrapper br-24 table_bx policy-box"
            flexDirection="column"
            justifyContent="start"
        >
            <div className="mx-auto mb-5">
                <h5 className="fz-24 fw-500">Terms & Conditions</h5>
            </div>
            <div className="text-end back-btn mb-4">
                <Link to="/term&condition" className="btn-design">
                    Back
                </Link>
            </div>
            <div className="mb-3 px-4 mt-2" dangerouslySetInnerHTML={{ __html: payload }} />
        </Box>
    );
};

export default TermView;

import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import '../App.css';
import Sidebar from "../layout/Sidebar";
import Header from './Header';

function Maincontainer() {
  const auth = localStorage.getItem('token');
  let navigate = useNavigate();

  useEffect(() => {
    if (!auth || auth === '' || auth === undefined) {
      navigate('/login')
    }
  }, [auth]);

  const [sidebarToggler, setSidebarToggler] = useState(false);

  const sidebarTogglerHandler = () => {
    setSidebarToggler(!sidebarToggler);
  }

  return (
    <>
      {auth ?
        <div>
          <div className='main_container'>
            <div className={sidebarToggler ? 'sidebar_wpr active' : 'sidebar_wpr'}>
              <Sidebar isToggleActive={sidebarToggler} />
            </div>
            <div className={sidebarToggler ? 'ctnt_wpr active' : 'ctnt_wpr'}>
              <Header toggleHandler={sidebarTogglerHandler} />
              <Outlet />
            </div>
          </div>
        </div>
        : <Navigate to="/login" />}
    </>
  )
}
export default Maincontainer;

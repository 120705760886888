import React from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

function PdfViewer({ pdfSrc }) {
    const docs = [
        { uri: pdfSrc }, // Remote file
    ];
    return (
        <div className="pdf-file">
            <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} config={{
                header: {
                    disableHeader: true,
                    disableFileName: true,
                    retainURLParams: false
                }
            }} />
        </div>
    );
}

export default PdfViewer;
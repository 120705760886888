import React, { useState } from "react";
import { Get_Offer_Detail } from "../services/Url";
import { useEffect } from "react";
import { getData } from "../services/Service";

const ImageBase = process.env.REACT_APP_IMAGE_BASE;

export const OfferCard = ({ offerID, isloading, discountHandler }) => {
  const [offerData, setOfferData] = useState();
  useEffect(() => {
    getData(Get_Offer_Detail, offerID, setOfferData, isloading);
  }, [offerID]);

  let discountVlaue;

  const text = offerData?.title;
  const pattern = /(\d+%)/g;
  const discountPattern = /(\d+)%/g;
  const discount = text?.match(discountPattern);
  const highlightedText = text?.replace(pattern, "<span>$1</span>"); //to highlight the % off number in title of offer

  useEffect(() => {
    if (discount) {
      discountVlaue = discount[0].match(/\d+/)[0];
      discountHandler(discountVlaue);
    }
  }, [offerData]);

  const encodedFileName = encodeURIComponent(offerData?.cover_image);
  const imageUrl = `url('${ImageBase}coverimage/${encodedFileName}?w=248&fit=crop&auto=format')`;
  return (
    <div
      className="offer-card"
      style={{
        backgroundImage: imageUrl,
      }}
    >
      <div className="offer-detail">
        <div>
          <h4 dangerouslySetInnerHTML={{ __html: highlightedText }}></h4>
          <h6>{offerData?.description}</h6>
        </div>
        <div>
          {" "}
          <button className="offer-btn" style={{ cursor: "default" }}>
            Applied
          </button>
        </div>
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { Loader } from "../../Components/Loader";
import { useNavigate, useParams } from "react-router-dom";
import editIcon from "../../Components/assets/images/edit-icon.svg";
import PlaceholderImg from "../../Components/assets/images/placeholder-img.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getData, updateData } from "../../services/Service";
import {
  Get_Holiday_Detail,
  Update_Holiday
} from "../../services/Url";
import { handleFileChange } from "../gallery/Gallery";
import { toast } from "react-toastify";

const ImageBase = process.env.REACT_APP_IMAGE_BASE;

const HolidayEdit = () => {
  const navigate = useNavigate();
  const [isloading, setIsloading] = useState(false);
  const [holidayData, setHolidayData] = useState();
  const [coverImg, setCoverImg] = useState(PlaceholderImg);

  const { id } = useParams();

  useEffect(() => {
    getData(Get_Holiday_Detail, id, setHolidayData, setIsloading);
  }, [id]);

  useEffect(() => {
    if (holidayData?.cover_image) {
      setCoverImg(ImageBase + "coverimage/" + holidayData?.cover_image);
    }
  }, [holidayData]);

  const validationSchema = Yup.object({
    title: Yup.string()
      .required("Title is required")
      .min(5, "Title must be at least 5 characters")
      .max(50, "Title can't exceed 50 characters"),
    description: Yup.string()
      .required("Description is required")
      .min(5, "Description must be at least 5 characters")
      .max(200, "Description can't exceed 200 characters"),
    price: Yup.number()
      .required("Price is required")
      .typeError("Please enter a valid price")
      .positive("Price must be a positive number")
      .min(0, "Price cannot be negative")
      .max(100000, "Price cannot exceed 100000"),
    duration: Yup.string()
      .required("Duration is required")
      .max(20, "Title can't exceed 20 characters"),
  });

  const formik = useFormik({
    initialValues: {
      title: holidayData ? holidayData.title : "",
      description: holidayData ? holidayData.description : "",
      price: holidayData ? holidayData.price : "",
      duration: holidayData ? holidayData.stayDuration : "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("cover_image", coverImg);
      formData.append("price", values.price);
      formData.append("description", values.description);
      formData.append("title", values.title);
      formData.append("stayDuration", values.duration);
      updateData(
        Update_Holiday,
        id,
        formData,
        setIsloading,
        "Holiday package updated"
      );
      navigate("/holidaypackage");
    },
    enableReinitialize: true,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      coverImg === undefined ||
      coverImg === PlaceholderImg ||
      coverImg === null
    ) {
      toast.error("Image is required!");
    } else {
      formik.handleSubmit(e);
    }
  };

  return (
    <Box
      display="flex"
      className="box-wrapper br-24 table_bx"
      flexDirection="column"
      justifyContent="start"
    >
      {isloading ? <Loader /> : ""}
      <div className="heading-btn_wrapper">
        <h5
          style={{ display: "inline-flex", cursor: "pointer" }}
          className="fz-24 fw-500"
        >
          Edit Holiday Package
        </h5>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row px-4">
          <div className="col-6 mx-auto">
            <Box className="cover-img-box">
              <img
                src={
                  coverImg === PlaceholderImg
                    ? PlaceholderImg
                    : coverImg ===
                      ImageBase + "coverimage/" + holidayData?.cover_image
                      ? coverImg
                      : coverImg === null && holidayData?.cover_image
                        ? `${ImageBase}coverimage/${holidayData?.cover_image}`
                        : URL.createObjectURL(coverImg)
                }
                alt="img"
                className="pro-img"
              />
              <label htmlFor="icon-button-file">
                <TextField
                  id="icon-button-file"
                  type="file"
                  name="image"
                  sx={{ display: "none" }}
                  onChange={(e) => {
                    handleFileChange(e, setCoverImg);
                  }}
                />
                <div className="edit-icon">
                  <img src={editIcon} alt="edit icon" />
                </div>
              </label>
            </Box>
          </div>
        </div>
        <div className="row">
          <div className="col-6 mx-auto px-4">
            <Box className="inpt_bx mt-4">
              <Typography variant="h6">Title</Typography>
              <TextField
                fullWidth
                name="title"
                id="title"
                placeholder="Title"
                {...formik.getFieldProps("title")}
                error={formik.touched.title && formik.errors.title}
                helperText={formik.touched.title && formik.errors.title}
                className={
                  formik.touched.title && formik.errors.title
                    ? "error-border"
                    : ""
                }
              />
            </Box>
            <Box className="inpt_bx mt-4">
              <Typography variant="h6">Description</Typography>
              <textarea
                placeholder="Type here..."
                rows="6"
                name="description"
                {...formik.getFieldProps("description")}
                className={
                  formik.touched.description && formik.errors.description
                    ? "error-border"
                    : ""
                }
              />
              {formik.touched.description && formik.errors.description && (
                <div className="error-text">{formik.errors.description}</div>
              )}
            </Box>
            <Box className="inpt_bx mt-4">
              <Typography variant="h6">Price</Typography>
              <TextField
                fullWidth
                name="price"
                placeholder="Price"
                type="number"
                {...formik.getFieldProps("price")}
                erros={formik.touched.price && formik.errors.price}
                helperText={formik.touched.price && formik.errors.price}
                className={
                  formik.touched.price && formik.errors.price
                    ? "error-border"
                    : ""
                }
              />
            </Box>
            <Box className="inpt_bx mt-4">
              <Typography variant="h6">Stay Duration</Typography>
              <TextField
                fullWidth
                name="duration"
                placeholder="Stay Duration"
                type="text"
                {...formik.getFieldProps("duration")}
                error={formik.touched.duration && formik.errors.duration}
                helperText={formik.touched.duration && formik.errors.duration}
                className={
                  formik.touched.duration && formik.errors.duration
                    ? "error-border"
                    : ""
                }
              />
            </Box>
            <div className="inpt_bx text-center mt-4">
              <Button className="btn btn-design" type="submit">
                Submit
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Box>
  );
};

export default HolidayEdit;

import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import editIcon from "../../Components/assets/images/edit-icon.svg";
import editIconActive from "../../Components/assets/images/edit-white.svg";
import LocIcon from "../../Components/assets/images/location-icon.svg";
import MailIcon from "../../Components/assets/images/mail-icon.svg";
import PhoneIcon from "../../Components/assets/images/phone-icon.svg";
import { get } from "../../services/Service";
import { Get_Contact } from "../../services/Url";

const ContactUs = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    get(Get_Contact, setData, setIsLoading);
  }, []);

  const contactData = [
    {
      label: data?.address,
      title: "Office Location",
      image: `${LocIcon}`,
      ref: 'https://goo.gl/maps/nXqgkMdXNLJ9XgFe7'
    },
    {
      label: data?.email,
      title: "Email Address",
      image: `${MailIcon}`,
      ref: `mailto:${data?.email}`
    },
    {
      label: data?.phone_number,
      title: "Phone Number",
      image: `${PhoneIcon}`,
      ref: `tel:${data?.phone_number}`
    },
  ];

  const ContactInfo = (props) => {
    return (
      <div className="contact-cards">
        <a href={props.item.ref} target='_blank' rel='noreferrer'>
          <img alt="img" src={props.item.image} />
        </a>
        <div>
          <h3>{props.item.title}</h3>
          <p>{props.item.label}</p>
        </div>
      </div>
    );
  };

  return (
    <Box
      display="flex"
      className="box-wrapper br-24 table_bx policy-box contact-box"
      flexDirection="column"
      justifyContent="start"
    >
      <div className="mx-auto contact-top">
        <h5 className="fz-24 fw-500">Contact Us</h5>
        <h3>How can we help you?</h3>
        <p>Have any question? We would love to hear from you.</p>
      </div>
      <div className="contact-card">
        <div className="card-head">
          <h3>Contact Info</h3>
          <Link className="edit-btn" to='/editcontact' state={data}>
            <button>
              <img src={editIcon} alt="edit-icon" />
              <img src={editIconActive} alt="edit-icon" />
            </button>
          </Link>
        </div>
        <div className="d-flex flex-column gap-4">
          {contactData?.map((item, index) => {
            return <ContactInfo key={index} item={item} />;
          })}
        </div>
      </div>
    </Box>
  );
};

export default ContactUs;

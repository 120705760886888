import React, { useState, useEffect } from "react";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import {
  Get_User_Detail,
  Get_User_Trip_Detail
} from "../../services/Url";
import Moment from "react-moment";
import { Loader } from "../../Components/Loader";
import { getData, post, searchData } from "../../services/Service";
import Search from "../../Components/Search";
import dummyProfile from "../../Components/assets/images/profilePicture.png";

const ImageBase = process.env.REACT_APP_IMAGE_BASE;

const UserTripDetail = () => {
  const [data, setData] = useState();
  const [userDetail, setUserDetail] = useState();
  const [isloading, setIsloading] = useState(true);
  const [error, setError] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);

  const { id } = useParams();

  const navigate = useNavigate();
  let pageData = JSON.stringify({
    search_string: "",
    page: page,
  });

  const completeUrl = Get_User_Trip_Detail + "/" + id;

  useEffect(() => {
    setTimeout(() => {
      getData(Get_User_Detail, id, setUserDetail, setIsloading);
      post(completeUrl, pageData, setData, setIsloading);
    }, 1500);
  }, []);

  useEffect(() => {
    //search API
    if (!/^[a-zA-Z0-9]/.test(searchText[0])) {
      //first character shouldn't be special character
      setError(true);
      return;
    }
    setError(false);
    let delay = 500;
    let timerId;
    let pageData = JSON.stringify({
      search_string: searchText,
      page: page,
    });
    const delayedSearch = () => {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        searchData(completeUrl, pageData, setData);
      }, delay);
    };
    delayedSearch();
    return () => clearTimeout(timerId);
  }, [searchText]);

  const pagingHandler = (event, value) => {
    //pagination API
    setPage(value);
    let pageData = {
      search_string: searchText,
      page: value,
    };
    pageData = JSON.stringify(pageData);
    post(completeUrl, pageData, setData, setIsloading);
  };


  const tableData = () => {
    return (
      <TableContainer className="tbl_mn br-24 br-btm-0 ">
        <div className="d-flex justify-content-between align-items-center mx-3">
          <h3 className="px-0">Trip Details</h3>
          <Search searchHandler={setSearchText} />
        </div>
        <Box className="table-responsive">
          <Table className="table-design table_center">
            <TableHead>
              <TableRow className="tbl_rw">
                <TableCell className="fw-bold">Booking ID </TableCell>
                <TableCell className="fw-bold">Start Date</TableCell>
                <TableCell className="fw-bold ">End Date</TableCell>
                <TableCell className="fw-bold td-width">Source</TableCell>
                <TableCell className="fw-bold td-width">Destination</TableCell>
                <TableCell className="fw-bold nowrap">Total Amount </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.docs?.length > 0 && !error ? (
                data?.docs?.map((item) => {
                  return (
                    <TableRow
                      key={item._id}
                      onClick={() =>
                        navigate(`/viewtrip/${item.userId}/${item._id}`)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <TableCell className="fz-16 nowrap">{item._id}</TableCell>
                      <TableCell className="fz-16 nowrap">
                        <Moment format="DD/MM/YYYY">
                          {item.trip_start_date}
                        </Moment>
                      </TableCell>
                      <TableCell className="fz-16 nowrap">
                        <Moment format="DD/MM/YYYY">
                          {item.trip_start_date}
                        </Moment>
                      </TableCell>
                      <TableCell className="fz-16 td-width">{item.source}</TableCell>
                      <TableCell className="fz-16 td-width">
                        {item.destination}
                      </TableCell>
                      <TableCell className="fz-16 nowrap">
                        {item.totalTicketAmount ? `$${item.totalTicketAmount}` : `$0`}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={12} className="empty-text text-center">
                    No data found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "end",
            marginTop: "30px",
          }}
        >
          {data?.docs?.length > 0 ? (
            <Stack spacing={2}>
              <Pagination count={data?.totalPages} onChange={pagingHandler} />
            </Stack>
          ) : (
            ""
          )}
        </Box>
      </TableContainer>
    );
  };
  return (
    <Box
      display="flex"
      className="box-wrapper br-24 table_bx"
      flexDirection="column"
      justifyContent="start"
    >
      {isloading ? <Loader /> : ""}
      <div className="heading-btn_wrapper">
        <h5
          style={{ display: "inline-flex", cursor: "pointer" }}
          className="fz-24 fw-500"
        >
          User Management
        </h5>
      </div>
      <div className="trip-box">
        <div className="row">
          <div className="col-xxl-3">
            <div className="user-info">
              <div className="user-img">
                <img
                  src={
                    userDetail?.profile_picture
                      ? ImageBase + "profile/" + userDetail?.profile_picture
                      : dummyProfile
                  }
                  alt="user-img"
                />
              </div>
              <div className="user-detail">
                <h4>{userDetail?.name}</h4>
                <p>{userDetail?.email}</p>
                <p>{userDetail?.country_code} {userDetail?.phone_number}</p>
              </div>
            </div>
          </div>
          <div className="col-xxl-9 mt-xxl-0 mt-5">
            <div className="trip-table">{tableData()}</div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default UserTripDetail;
